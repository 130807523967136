import { analyzeData } from '../openai';
import type { DataSource } from '../types/dataSource';

export interface ChatMessage {
  id: string;
  type: 'user' | 'assistant' | 'error';
  content: string;
  timestamp: Date;
}

export async function processUserMessage(
  message: string,
  dataSources: DataSource[]
): Promise<string> {
  try {
    if (dataSources.length === 0) {
      throw new Error('Please connect a data source first to enable AI analysis. Go to the Data Sources page to upload your data.');
    }

    // Get the most recently updated data source with valid data
    const dataSource = dataSources
      .filter(ds => ds.status === 'connected' && ds.data && ds.data.length > 0)
      .sort((a, b) => new Date(b.lastSync).getTime() - new Date(a.lastSync).getTime())[0];

    if (!dataSource) {
      throw new Error('No valid data source found. Please ensure your data is properly uploaded in the Data Sources page.');
    }

    // Log data structure for debugging
    console.log('Processing data source:', {
      name: dataSource.name,
      rowCount: dataSource.data.length,
      columns: Object.keys(dataSource.data[0] || {})
    });

    // Generate a context-aware prompt based on the user's question
    const contextPrompt = `
      Based on the following question: "${message}"

      Analyze the provided dataset and give a specific, detailed answer. Focus on:
      1. Information directly relevant to the question
      2. Supporting data points and statistics
      3. Any patterns or trends related to the query
      4. Actionable insights when applicable

      The dataset has ${dataSource.data.length} rows and contains the following columns:
      ${Object.keys(dataSource.data[0] || {}).join(', ')}

      Please format the response with clear sections and bullet points where appropriate.
    `;

    // Analyze the data with the enhanced prompt
    const response = await analyzeData(dataSource.data, contextPrompt);
    return response;
  } catch (error) {
    console.error('Message processing error:', error);
    throw error;
  }
}

export function generateSystemMessage(): ChatMessage {
  return {
    id: '1',
    type: 'assistant',
    content: `Hello! I'm your AI data assistant. I can help analyze your data and answer specific questions about your datasets.

Some things you can ask me:
• Analyze trends and patterns in your data
• Calculate statistics and metrics
• Find correlations between different variables
• Generate insights and recommendations
• Explain unusual patterns or anomalies

What would you like to know about your data?`,
    timestamp: new Date()
  };
}