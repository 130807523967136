import React, { useState, useEffect } from 'react';
import { Bot, Loader2, AlertCircle } from 'lucide-react';
import OpenAI from 'openai';
import type { StockData } from '../../lib/api/alpha-vantage/types';

interface AiStockAnalysisProps {
  stockData: StockData;
  symbol: string;
}

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export default function AiStockAnalysis({ stockData, symbol }: AiStockAnalysisProps) {
  const [analysis, setAnalysis] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const generateAnalysis = async () => {
      setIsLoading(true);
      setError('');

      try {
        const prompt = `Analyze the following stock data for ${symbol}:

Company Profile:
- Name: ${stockData.profile.name}
- Industry: ${stockData.profile.industry}
- Market Cap: $${(stockData.profile.marketCap / 1e9).toFixed(2)}B

Current Performance:
- Current Price: $${stockData.quote.currentPrice}
- Daily Change: ${stockData.trends.daily.toFixed(2)}%
- Weekly Change: ${stockData.trends.weekly.toFixed(2)}%
- Monthly Change: ${stockData.trends.monthly.toFixed(2)}%
- Volume: ${stockData.quote.volume}

Technical Analysis:
- 52-Week High: $${stockData.quote.highPrice}
- 52-Week Low: $${stockData.quote.lowPrice}
- Opening Price: $${stockData.quote.openPrice}
- Previous Close: $${stockData.quote.previousClose}

Please provide a comprehensive analysis including:
1. Current market position and recent performance
2. Key technical indicators and what they suggest
3. Industry context and competitive position
4. Short-term (1-2 weeks) and medium-term (1-3 months) predictions
5. Potential risks and opportunities
6. Trading volume analysis and what it indicates
7. Specific price targets and support/resistance levels

Format the response in clear sections with bullet points where appropriate.`;

        const response = await openai.chat.completions.create({
          model: "gpt-3.5-turbo",
          messages: [
            {
              role: "system",
              content: "You are a professional stock market analyst providing detailed, data-driven analysis. Focus on actionable insights and clear predictions based on the provided data. Always maintain a balanced perspective and highlight both opportunities and risks."
            },
            {
              role: "user",
              content: prompt
            }
          ],
          temperature: 0.7,
          max_tokens: 1000
        });

        setAnalysis(response.choices[0].message.content || 'No analysis available.');
      } catch (err) {
        console.error('AI analysis error:', err);
        setError(err instanceof Error ? err.message : 'Failed to generate analysis');
      } finally {
        setIsLoading(false);
      }
    };

    if (stockData) {
      generateAnalysis();
    }
  }, [stockData, symbol]);

  if (isLoading) {
    return (
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
        <div className="flex items-center space-x-3 mb-4">
          <Bot className="h-6 w-6 text-indigo-600 dark:text-indigo-400" />
          <h3 className="text-lg font-semibold dark:text-white">AI Analysis</h3>
        </div>
        <div className="flex items-center justify-center py-8">
          <Loader2 className="h-8 w-8 text-indigo-600 dark:text-indigo-400 animate-spin" />
          <span className="ml-3 text-gray-600 dark:text-gray-400">Generating analysis...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
        <div className="flex items-center space-x-3 mb-4">
          <AlertCircle className="h-6 w-6 text-red-600 dark:text-red-400" />
          <h3 className="text-lg font-semibold dark:text-white">Analysis Unavailable</h3>
        </div>
        <p className="text-red-600 dark:text-red-400">{error}</p>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
      <div className="flex items-center space-x-3 mb-4">
        <Bot className="h-6 w-6 text-indigo-600 dark:text-indigo-400" />
        <h3 className="text-lg font-semibold dark:text-white">AI Analysis</h3>
      </div>
      
      <div className="prose dark:prose-invert max-w-none">
        {analysis.split('\n').map((paragraph, index) => (
          <p key={index} className="text-gray-600 dark:text-gray-300 mb-4">
            {paragraph}
          </p>
        ))}
      </div>

      <div className="mt-6 p-4 bg-yellow-50 dark:bg-yellow-900/20 rounded-lg">
        <p className="text-sm text-yellow-800 dark:text-yellow-200">
          <strong>Disclaimer:</strong> This analysis is generated by AI and should not be considered as financial advice. 
          Always conduct your own research and consult with financial professionals before making investment decisions.
        </p>
      </div>
    </div>
  );
}