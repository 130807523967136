import { format } from 'date-fns';
import type { StockQuote, CompanyProfile, ChartData } from './types';

function sanitizeNumber(value: any): number {
  if (typeof value === 'number' && !isNaN(value)) return value;
  if (typeof value === 'string') {
    const cleaned = value.replace(/[^0-9.-]/g, '');
    const num = Number(cleaned);
    return isNaN(num) ? 0 : num;
  }
  return 0;
}

export function processQuoteData(data: any): StockQuote {
  const quote = data?.['Global Quote'] || {};
  
  return {
    currentPrice: sanitizeNumber(quote['05. price']),
    highPrice: sanitizeNumber(quote['03. high']),
    lowPrice: sanitizeNumber(quote['04. low']),
    openPrice: sanitizeNumber(quote['02. open']),
    previousClose: sanitizeNumber(quote['08. previous close']),
    volume: sanitizeNumber(quote['06. volume']),
    change: sanitizeNumber(quote['09. change']),
    changePercent: sanitizeNumber(quote['10. change percent']?.replace('%', ''))
  };
}

export function processProfileData(data: any): CompanyProfile {
  if (!data || typeof data !== 'object') {
    return {
      name: 'N/A',
      description: '',
      exchange: 'N/A',
      sector: 'N/A',
      industry: 'N/A',
      marketCap: 0
    };
  }

  return {
    name: String(data.Name || 'N/A'),
    description: String(data.Description || ''),
    exchange: String(data.Exchange || 'N/A'),
    sector: String(data.Sector || 'N/A'),
    industry: String(data.Industry || 'N/A'),
    marketCap: sanitizeNumber(data.MarketCapitalization)
  };
}

export function processChartData(data: any): ChartData[] {
  const timeSeries = data?.['Time Series (Daily)'];
  if (!timeSeries || typeof timeSeries !== 'object') {
    return [];
  }

  return Object.entries(timeSeries)
    .slice(0, 90) // Last 90 days
    .map(([date, values]: [string, any]) => {
      if (!values || typeof values !== 'object') return null;
      
      return {
        date: format(new Date(date), 'MMM dd'),
        price: sanitizeNumber(values['4. close']),
        volume: sanitizeNumber(values['5. volume'])
      };
    })
    .filter((item): item is ChartData => 
      item !== null && 
      item.price > 0 && 
      !isNaN(new Date(item.date).getTime())
    )
    .reverse();
}