import React, { useState } from 'react';
import { Check, AlertCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../store/useStore';

const features = [
  'Unlimited Data Sources',
  'Advanced AI Analytics',
  'Team Collaboration'
];

export default function Pricing() {
  const navigate = useNavigate();
  const { isAuthenticated } = useStore();
  const [error, setError] = useState('');

  const handleGetStarted = () => {
    navigate('/signup');
  };

  return (
    <section id="pricing" className="py-20 bg-gray-50 dark:bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white sm:text-4xl mb-4">
            Simple, transparent pricing
          </h2>
          <p className="text-xl text-gray-600 dark:text-gray-400">
            Start for free, upgrade when you need more
          </p>
        </div>

        {error && (
          <div className="max-w-3xl mx-auto mb-8 p-4 bg-red-50 dark:bg-red-900/50 rounded-lg flex items-center text-red-700 dark:text-red-200">
            <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
            <p>{error}</p>
          </div>
        )}

        <div className="max-w-3xl mx-auto bg-white dark:bg-gray-900 rounded-2xl shadow-lg overflow-hidden">
          <div className="px-6 py-8 sm:p-10">
            <div className="flex justify-between items-baseline">
              <div>
                <h3 className="text-2xl font-bold text-gray-900 dark:text-white">Free Forever</h3>
                <p className="mt-2 text-gray-600 dark:text-gray-400">Get started with basic features</p>
              </div>
              <div className="text-right">
                <p className="text-4xl font-bold text-gray-900 dark:text-white">$0</p>
                <p className="text-sm text-gray-500 dark:text-gray-400">One data source included</p>
              </div>
            </div>

            <div className="mt-8 border-t border-gray-200 dark:border-gray-700 pt-8">
              <p className="text-sm text-gray-500 dark:text-gray-400 mb-4">
                Upgrade to Pro for just <span className="font-bold text-indigo-600 dark:text-indigo-400">$7.99</span> (one-time payment) to unlock:
              </p>
              <ul className="space-y-4">
                {features.map((feature) => (
                  <li key={feature} className="flex items-start">
                    <Check className="h-5 w-5 text-green-500 dark:text-green-400 mt-0.5 mr-3" />
                    <span className="text-gray-600 dark:text-gray-300">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="px-6 py-8 sm:px-10 bg-gray-50 dark:bg-gray-800 space-y-4">
            <button
              onClick={handleGetStarted}
              className="w-full py-3 px-4 rounded-lg font-semibold bg-indigo-600 text-white hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 transition-colors"
            >
              Get Started Free
            </button>
            <p className="text-center text-sm text-gray-500 dark:text-gray-400">
              No credit card required to start. Upgrade anytime.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}