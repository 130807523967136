import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import Footer from '../components/Footer';

export default function PrivacyPage() {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <Link
          to="/"
          className="inline-flex items-center text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-300 mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to home
        </Link>

        <div className="prose dark:prose-invert max-w-none">
          <h1>Privacy Policy</h1>
          <p className="lead">Last updated: {new Date().toLocaleDateString()}</p>

          <h2>Introduction</h2>
          <p>
            Welcome to Algozi's Privacy Policy. This document outlines how we collect, use, and protect your information
            when you use our AI-powered data analytics platform.
          </p>

          <h2>AI Technology and Data Processing</h2>
          <p>
            Algozi uses OpenAI's technology to provide AI-powered analytics and insights. Here's what you need to know:
          </p>
          <ul>
            <li>
              <strong>Data Processing:</strong> When you use our AI features, your data is processed using OpenAI's APIs
              to generate insights, analysis, and recommendations.
            </li>
            <li>
              <strong>Data Security:</strong> All data sent to OpenAI is encrypted in transit and we do not store your
              raw data on OpenAI's servers.
            </li>
            <li>
              <strong>Data Usage:</strong> The AI models may use your queries to improve their performance, but your
              business data remains confidential and is not used to train the models.
            </li>
          </ul>

          <h2>Information We Collect</h2>
          <p>We collect and process the following types of information:</p>
          <ul>
            <li>Account information (name, email, organization details)</li>
            <li>Usage data and analytics</li>
            <li>Data you upload for analysis</li>
            <li>Payment information (processed securely by Stripe)</li>
          </ul>

          <h2>How We Use Your Information</h2>
          <p>We use your information to:</p>
          <ul>
            <li>Provide and improve our services</li>
            <li>Generate AI-powered insights and analytics</li>
            <li>Process payments and manage subscriptions</li>
            <li>Send important updates and notifications</li>
            <li>Ensure platform security and prevent fraud</li>
          </ul>

          <h2>Data Security</h2>
          <p>
            We implement robust security measures to protect your data, including:
          </p>
          <ul>
            <li>End-to-end encryption for data transmission</li>
            <li>Secure data storage with regular backups</li>
            <li>Access controls and authentication</li>
            <li>Regular security audits and monitoring</li>
          </ul>

          <h2>Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access your personal data</li>
            <li>Correct inaccurate data</li>
            <li>Request data deletion</li>
            <li>Export your data</li>
            <li>Opt-out of certain data processing</li>
          </ul>

          <h2>Contact Us</h2>
          <p>
            If you have any questions about this Privacy Policy, please contact us at{' '}
            <a href="mailto:privacy@algozi.com">privacy@algozi.com</a>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}