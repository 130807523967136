import axios from 'axios';

const FINNHUB_API_KEY = 'cn7jd0pr01qjch2mf8u0cn7jd0pr01qjch2mf8ug';

export const marketSectors = [
  { id: 'technology', name: 'Technology', symbols: ['AAPL', 'MSFT', 'GOOGL'] },
  { id: 'healthcare', name: 'Healthcare', symbols: ['JNJ', 'PFE', 'UNH'] },
  { id: 'finance', name: 'Finance', symbols: ['JPM', 'BAC', 'GS'] },
  { id: 'energy', name: 'Energy', symbols: ['XOM', 'CVX', 'COP'] },
  { id: 'consumer', name: 'Consumer Goods', symbols: ['PG', 'KO', 'WMT'] }
];

// Mock data for development
const mockStockData = {
  technology: { trend: 2.5, confidence: 85 },
  healthcare: { trend: 1.8, confidence: 78 },
  finance: { trend: -0.5, confidence: 82 },
  energy: { trend: 3.2, confidence: 75 },
  consumer: { trend: 1.2, confidence: 80 }
};

export async function fetchSectorData(sectorId: string) {
  try {
    const sector = marketSectors.find(s => s.id === sectorId);
    if (!sector) {
      throw new Error(`Invalid sector ID: ${sectorId}`);
    }

    // Generate mock data for each symbol
    return sector.symbols.map(symbol => ({
      symbol,
      quote: {
        currentPrice: Math.random() * 1000 + 100,
        highPrice: Math.random() * 1000 + 200,
        lowPrice: Math.random() * 1000 + 50,
        openPrice: Math.random() * 1000 + 100,
        previousClose: Math.random() * 1000 + 100,
        timestamp: Date.now()
      },
      trends: {
        daily: Number((Math.random() * 6 - 3).toFixed(2)),
        weekly: Number((Math.random() * 10 - 5).toFixed(2)),
        monthly: Number((Math.random() * 20 - 10).toFixed(2))
      }
    }));
  } catch (error) {
    console.error('Error fetching sector data:', error);
    throw error;
  }
}

export async function fetchSectorNews(sectorId: string) {
  try {
    const sector = marketSectors.find(s => s.id === sectorId);
    if (!sector) {
      throw new Error(`Invalid sector ID: ${sectorId}`);
    }

    // Generate mock news data
    return Array.from({ length: 5 }, (_, i) => ({
      datetime: Date.now() - i * 3600000,
      headline: `${sector.name} Sector News ${i + 1}`,
      summary: `This is a mock summary for ${sector.name} sector news item ${i + 1}. It contains relevant information about market trends and company updates.`,
      url: 'https://example.com/news',
      source: 'Mock News Source'
    }));
  } catch (error) {
    console.error('Error fetching sector news:', error);
    throw error;
  }
}

export function predictTrend(sectorId: string, data: number[]) {
  try {
    const mockData = mockStockData[sectorId as keyof typeof mockStockData];
    if (!mockData) {
      throw new Error(`Invalid sector ID: ${sectorId}`);
    }
    
    return {
      prediction: Number((Math.random() * 4 - 2).toFixed(2)),
      confidence: Math.floor(Math.random() * 20 + 70) // Random confidence between 70-90%
    };
  } catch (error) {
    console.error('Error predicting trend:', error);
    throw error;
  }
}