import React from 'react';
import { ExternalLink, Info } from 'lucide-react';
import { format } from 'date-fns';
import { NewsArticle } from '../../lib/newsApi';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';

interface SectorAnalysisProps {
  sectorData: any[];
  predictions: {
    prediction: number;
    confidence: number;
  };
  news: NewsArticle[];
}

const getSentimentColor = (sentiment?: { score: number; label: string }) => {
  if (!sentiment) return {
    bg: 'bg-gray-100 dark:bg-gray-700',
    text: 'text-gray-800 dark:text-gray-200',
    icon: '●'
  };
  
  switch (sentiment.label) {
    case 'Positive':
      return {
        bg: 'bg-green-100 dark:bg-green-800',
        text: 'text-green-800 dark:text-green-100',
        icon: '▲'
      };
    case 'Negative':
      return {
        bg: 'bg-red-100 dark:bg-red-800',
        text: 'text-red-800 dark:text-red-100',
        icon: '▼'
      };
    default:
      return {
        bg: 'bg-yellow-100 dark:bg-yellow-800',
        text: 'text-yellow-800 dark:text-yellow-100',
        icon: '■'
      };
  }
};

const formatDate = (dateString: string) => {
  try {
    const date = new Date(dateString);
    return format(date, 'MMM d, yyyy');
  } catch (error) {
    return dateString;
  }
};

export default function SectorAnalysis({ sectorData, predictions, news }: SectorAnalysisProps) {
  const trendData = sectorData.map(stock => ({
    name: stock.symbol,
    daily: stock.trends.daily,
    weekly: stock.trends.weekly,
    monthly: stock.trends.monthly
  }));

  return (
    <div className="space-y-6">
      {/* Prediction Alert */}
      <div className={`p-4 rounded-lg ${
        predictions.prediction > 0 ? 'bg-green-50 dark:bg-green-900' : 'bg-red-50 dark:bg-red-900'
      }`}>
        <div className="flex items-center">
          <Info className={`h-5 w-5 mr-2 ${
            predictions.prediction > 0 
              ? 'text-green-600 dark:text-green-400' 
              : 'text-red-600 dark:text-red-400'
          }`} />
          <div>
            <h4 className="font-medium dark:text-white">Market Prediction</h4>
            <p className="text-sm dark:text-gray-300">
              This sector is predicted to {predictions.prediction > 0 ? 'grow' : 'decline'} by{' '}
              <span className="font-medium">
                {Math.abs(predictions.prediction)}%
              </span>{' '}
              (Confidence: {predictions.confidence}%)
            </p>
          </div>
        </div>
      </div>

      {/* Performance Chart */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg">
        <div className="flex items-center justify-between mb-4">
          <h3 className="text-lg font-semibold dark:text-white">Performance Trends</h3>
        </div>
        <div className="h-64">
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={trendData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="daily" name="Daily Change" fill="#4F46E5" />
              <Bar dataKey="weekly" name="Weekly Change" fill="#10B981" />
              <Bar dataKey="monthly" name="Monthly Change" fill="#F59E0B" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Latest News */}
      <div className="bg-white dark:bg-gray-800 p-6 rounded-lg">
        <h3 className="text-lg font-semibold mb-6 dark:text-white flex items-center justify-between">
          <span>Latest Industry News</span>
          <div className="flex items-center space-x-4 text-sm">
            <span className="flex items-center">
              <span className="text-green-600 mr-1">▲</span>
              <span className="text-gray-600 dark:text-gray-400">Positive</span>
            </span>
            <span className="flex items-center">
              <span className="text-red-600 mr-1">▼</span>
              <span className="text-gray-600 dark:text-gray-400">Negative</span>
            </span>
            <span className="flex items-center">
              <span className="text-yellow-600 mr-1">■</span>
              <span className="text-gray-600 dark:text-gray-400">Neutral</span>
            </span>
          </div>
        </h3>
        <div className="space-y-4">
          {news.map((article, index) => {
            const sentimentStyle = getSentimentColor(article.sentiment);
            return (
              <div
                key={index}
                className="border border-gray-200 dark:border-gray-700 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700/50 transition-colors p-4"
              >
                <div className="flex items-start space-x-4">
                  <div className="flex-1">
                    <div className="flex items-center justify-between mb-2">
                      <h4 className="font-medium dark:text-white flex items-center">
                        <span className={`mr-2 ${sentimentStyle.text}`}>
                          {sentimentStyle.icon}
                        </span>
                        {article.title}
                      </h4>
                      <span className={`px-3 py-1 rounded-full text-xs font-medium ${sentimentStyle.bg} ${sentimentStyle.text}`}>
                        {article.sentiment.label}
                        <span className="ml-1 opacity-75">
                          ({Math.abs(article.sentiment.score * 100).toFixed(0)}%)
                        </span>
                      </span>
                    </div>
                    <p className="text-sm text-gray-600 dark:text-gray-400 mb-3 line-clamp-2">
                      {article.description}
                    </p>
                    <div className="flex items-center justify-between text-sm">
                      <div className="flex items-center space-x-3 text-gray-500 dark:text-gray-400">
                        <span>{formatDate(article.publishedAt)}</span>
                        <span>•</span>
                        <span>{article.source.name}</span>
                      </div>
                      <a
                        href={article.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-flex items-center text-indigo-600 hover:text-indigo-500 dark:text-indigo-400 font-medium"
                      >
                        Read full article
                        <ExternalLink className="h-4 w-4 ml-1" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}