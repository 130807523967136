import React, { useState } from 'react';
import DashboardLayout from '../../components/dashboard/Layout';
import { useStore } from '../../store/useStore';
import { Users, UserPlus, Mail, Shield, X, Check, Loader2, AlertCircle } from 'lucide-react';

export default function TeamPage() {
  const { userData, teamMembers, addTeamMember, updateTeamMember, removeTeamMember } = useStore();
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');
  const [inviteRole, setInviteRole] = useState<'editor' | 'viewer'>('editor');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  // Combine store team members with the current user
  const allTeamMembers = [
    // Current user as admin
    {
      id: userData?.id || '1',
      email: userData?.email || '',
      firstName: userData?.firstName || '',
      lastName: userData?.lastName || '',
      role: 'admin' as const,
      status: 'active' as const
    },
    ...(teamMembers || [])
  ];

  const handleInvite = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');
    setSuccess('');

    try {
      if (!inviteEmail.includes('@')) {
        throw new Error('Please enter a valid email address');
      }

      if (allTeamMembers.some(member => member.email === inviteEmail)) {
        throw new Error('This user is already a team member');
      }

      const newMember = {
        id: Math.random().toString(36).substr(2, 9),
        email: inviteEmail,
        role: inviteRole,
        status: 'invited' as const
      };

      addTeamMember(newMember);
      setSuccess('Invitation sent successfully');
      setInviteEmail('');
      setShowInviteModal(false);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to send invitation');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Team Management</h2>
        <p className="mt-1 text-gray-600 dark:text-gray-400">Manage your team members and their permissions.</p>
      </div>

      {error && (
        <div className="mb-4 p-4 bg-red-50 dark:bg-red-900/50 rounded-lg text-red-700 dark:text-red-200 flex items-center">
          <AlertCircle className="h-5 w-5 mr-2" />
          {error}
        </div>
      )}

      {success && (
        <div className="mb-4 p-4 bg-green-50 dark:bg-green-900/50 rounded-lg text-green-700 dark:text-green-200 flex items-center">
          <Check className="h-5 w-5 mr-2" />
          {success}
        </div>
      )}

      {/* Quick stats */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
          <Users className="h-8 w-8 text-indigo-600 dark:text-indigo-400 mb-4" />
          <h3 className="text-lg font-semibold dark:text-white">Total Members</h3>
          <p className="text-3xl font-semibold dark:text-white">{allTeamMembers.length}</p>
        </div>
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
          <Shield className="h-8 w-8 text-indigo-600 dark:text-indigo-400 mb-4" />
          <h3 className="text-lg font-semibold dark:text-white">Active Members</h3>
          <p className="text-3xl font-semibold dark:text-white">
            {allTeamMembers.filter(m => m.status === 'active').length}
          </p>
        </div>
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
          <Mail className="h-8 w-8 text-indigo-600 dark:text-indigo-400 mb-4" />
          <h3 className="text-lg font-semibold dark:text-white">Pending Invites</h3>
          <p className="text-3xl font-semibold dark:text-white">
            {allTeamMembers.filter(m => m.status === 'invited').length}
          </p>
        </div>
      </div>

      {/* Team members list */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm">
        <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex justify-between items-center">
          <h3 className="text-lg font-semibold dark:text-white">Team Members</h3>
          <button
            onClick={() => setShowInviteModal(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600"
          >
            <UserPlus className="h-5 w-5 mr-2" />
            Add Member
          </button>
        </div>

        <div className="p-6">
          <div className="space-y-4">
            {allTeamMembers.map((member) => (
              <div key={member.id} className="flex items-center justify-between p-4 border dark:border-gray-700 rounded-lg">
                <div className="flex items-center">
                  <div className="h-10 w-10 rounded-full bg-indigo-600 dark:bg-indigo-500 flex items-center justify-center text-white font-semibold">
                    {member.firstName?.[0] || member.email[0].toUpperCase()}
                    {member.lastName?.[0]}
                  </div>
                  <div className="ml-4">
                    <h4 className="font-medium dark:text-white">
                      {member.firstName ? `${member.firstName} ${member.lastName}` : member.email}
                      {member.id === userData?.id && ' (You)'}
                    </h4>
                    <p className="text-sm text-gray-600 dark:text-gray-400">{member.email}</p>
                  </div>
                </div>
                <div className="flex items-center space-x-4">
                  <select
                    value={member.role}
                    onChange={(e) => updateTeamMember(member.id, { role: e.target.value as 'admin' | 'editor' | 'viewer' })}
                    disabled={member.role === 'admin' || member.id === userData?.id}
                    className="text-sm border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  >
                    <option value="admin">Admin</option>
                    <option value="editor">Editor</option>
                    <option value="viewer">Viewer</option>
                  </select>
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    member.status === 'active'
                      ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
                      : member.status === 'invited'
                      ? 'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100'
                      : 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100'
                  }`}>
                    {member.status}
                  </span>
                  {member.id !== userData?.id && member.role !== 'admin' && (
                    <button
                      onClick={() => removeTeamMember(member.id)}
                      className="text-red-600 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Invite Modal */}
      {showInviteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white dark:bg-gray-800 rounded-lg max-w-md w-full p-6">
            <h3 className="text-lg font-semibold mb-4 dark:text-white">Invite Team Member</h3>
            <form onSubmit={handleInvite}>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Email Address
                  </label>
                  <input
                    type="email"
                    value={inviteEmail}
                    onChange={(e) => setInviteEmail(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                    Role
                  </label>
                  <select
                    value={inviteRole}
                    onChange={(e) => setInviteRole(e.target.value as 'editor' | 'viewer')}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                  >
                    <option value="editor">Editor</option>
                    <option value="viewer">Viewer</option>
                  </select>
                </div>
              </div>

              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setShowInviteModal(false)}
                  className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isLoading}
                  className="px-4 py-2 bg-indigo-600 text-white rounded-md text-sm font-medium hover:bg-indigo-700 disabled:opacity-50 dark:bg-indigo-500 dark:hover:bg-indigo-600 inline-flex items-center"
                >
                  {isLoading ? (
                    <>
                      <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                      Sending...
                    </>
                  ) : (
                    'Send Invitation'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </DashboardLayout>
  );
}