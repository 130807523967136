import { alphaVantageClient } from './client';
import { processQuoteData, processProfileData, processChartData } from './utils';
import type { StockSearchResult, StockData } from './types';

export async function searchStocks(query: string): Promise<StockSearchResult[]> {
  if (!query || query.length < 2) return [];

  try {
    const { data } = await alphaVantageClient.get('', {
      params: {
        function: 'SYMBOL_SEARCH',
        keywords: query
      }
    });

    if (!data?.bestMatches) return [];

    return data.bestMatches
      .filter((match: any) => match && typeof match === 'object')
      .map((match: any) => ({
        symbol: String(match['1. symbol'] || ''),
        name: String(match['2. name'] || ''),
        type: String(match['3. type'] || ''),
        region: String(match['4. region'] || '')
      }))
      .filter(result => 
        result.symbol && 
        result.name && 
        result.region.includes('United States')
      )
      .slice(0, 10);
  } catch (error) {
    console.error('Stock search error:', error);
    return [];
  }
}

export async function getStockData(symbol: string): Promise<StockData> {
  try {
    // Fetch all data in parallel
    const [quoteResponse, overviewResponse, dailyResponse] = await Promise.all([
      // Global Quote
      alphaVantageClient.get('', {
        params: {
          function: 'GLOBAL_QUOTE',
          symbol
        }
      }),
      // Company Overview
      alphaVantageClient.get('', {
        params: {
          function: 'OVERVIEW',
          symbol
        }
      }),
      // Daily Time Series
      alphaVantageClient.get('', {
        params: {
          function: 'TIME_SERIES_DAILY',
          symbol,
          outputsize: 'compact'
        }
      })
    ]);

    // Process the responses
    const quote = processQuoteData(quoteResponse.data);
    const profile = processProfileData(overviewResponse.data);
    const chartData = processChartData(dailyResponse.data);

    // Validate the data
    if (!quote.currentPrice && !profile.name && chartData.length === 0) {
      throw new Error('No data available for this stock symbol');
    }

    return {
      quote,
      profile,
      chartData,
      trends: {
        daily: calculateTrend(chartData, 1),
        weekly: calculateTrend(chartData, 7),
        monthly: calculateTrend(chartData, 30)
      }
    };
  } catch (error) {
    console.error('Error fetching stock data:', error);
    
    if (error instanceof Error) {
      if (error.message.includes('API call frequency')) {
        throw new Error('API rate limit exceeded. Please try again in a minute.');
      }
      throw error;
    }
    
    throw new Error('Failed to fetch stock data. Please try again later.');
  }
}

function calculateTrend(chartData: any[], days: number): number {
  if (chartData.length < days) return 0;
  
  const recent = chartData[chartData.length - 1].price;
  const past = chartData[Math.max(0, chartData.length - days)].price;
  
  return ((recent - past) / past) * 100;
}