import React from 'react';
import { TrendingUp, TrendingDown, Info } from 'lucide-react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

interface MarketSectorCardProps {
  sector: {
    id: string;
    name: string;
  };
  data: Array<{
    symbol: string;
    quote: {
      currentPrice: number;
      highPrice: number;
      lowPrice: number;
      openPrice: number;
      previousClose: number;
      timestamp: number;
    };
    trends: {
      daily: number;
      weekly: number;
      monthly: number;
    };
  }>;
  onSelect: () => void;
  selected: boolean;
}

const CHART_COLORS = {
  positive: '#4F46E5', // Indigo
  negative: '#EF4444', // Red
};

export default function MarketSectorCard({ sector, data, onSelect, selected }: MarketSectorCardProps) {
  const hasData = data.length > 0;
  const averageTrend = hasData
    ? data.reduce((acc, stock) => acc + stock.trends.daily, 0) / data.length
    : 0;
  const isPositive = averageTrend > 0;

  const chartData = hasData
    ? data.map(stock => ({
        name: stock.symbol,
        value: stock.trends.daily,
        fill: stock.trends.daily >= 0 ? CHART_COLORS.positive : CHART_COLORS.negative
      }))
    : [];

  return (
    <div
      onClick={onSelect}
      className={`p-6 rounded-lg cursor-pointer transition-all ${
        selected
          ? 'bg-indigo-50 border-2 border-indigo-500 dark:bg-indigo-900 dark:border-indigo-400'
          : 'bg-white hover:shadow-md border border-gray-200 dark:bg-gray-800 dark:border-gray-700 dark:hover:border-gray-600'
      }`}
    >
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-center">
          <h3 className="text-lg font-semibold dark:text-white">{sector.name}</h3>
          <div className="relative group ml-2">
            <Info className="h-4 w-4 text-gray-400 cursor-help" />
            <div className="hidden group-hover:block absolute left-0 w-64 p-3 mt-2 text-sm bg-white dark:bg-gray-700 rounded-lg shadow-lg border border-gray-200 dark:border-gray-600 z-10">
              <p className="font-medium text-gray-900 dark:text-white mb-2">Sector Overview</p>
              <ul className="space-y-1 text-gray-600 dark:text-gray-300">
                <li>• Shows daily price movements</li>
                <li>• Tracks top stocks in {sector.name}</li>
                <li>• Click to view detailed analysis</li>
              </ul>
            </div>
          </div>
        </div>
        {hasData && (
          <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
            isPositive 
              ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
              : 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100'
          }`}>
            {isPositive ? (
              <TrendingUp className="h-4 w-4 mr-1" />
            ) : (
              <TrendingDown className="h-4 w-4 mr-1" />
            )}
            {Math.abs(averageTrend).toFixed(2)}%
          </span>
        )}
      </div>

      <div className="h-32 mb-4">
        {hasData ? (
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={chartData} margin={{ top: 5, right: 5, bottom: 5, left: 5 }}>
              <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
              <XAxis 
                dataKey="name" 
                tick={{ fontSize: 10 }}
                stroke={document.documentElement.classList.contains('dark') ? '#9CA3AF' : '#6B7280'}
              />
              <YAxis 
                tick={{ fontSize: 10 }}
                stroke={document.documentElement.classList.contains('dark') ? '#9CA3AF' : '#6B7280'}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: document.documentElement.classList.contains('dark') ? '#1F2937' : '#FFFFFF',
                  border: 'none',
                  borderRadius: '0.5rem',
                  boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
                }}
                labelStyle={{
                  color: document.documentElement.classList.contains('dark') ? '#F3F4F6' : '#111827',
                }}
              />
              <Bar 
                dataKey="value" 
                fill={CHART_COLORS.positive}
                radius={[4, 4, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <div className="flex items-center justify-center h-full text-gray-400 dark:text-gray-600">
            Click to load market data
          </div>
        )}
      </div>

      {hasData && (
        <div className="grid grid-cols-3 gap-2 text-sm">
          {data.map(stock => (
            <div key={stock.symbol} className="text-center group relative">
              <p className="font-medium dark:text-white">{stock.symbol}</p>
              <span className={`${
                stock.trends.daily > 0 
                  ? 'text-green-600 dark:text-green-400'
                  : 'text-red-600 dark:text-red-400'
              }`}>
                {stock.trends.daily > 0 ? '+' : ''}{stock.trends.daily.toFixed(2)}%
              </span>
              <div className="hidden group-hover:block absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 w-48 p-2 text-xs bg-white dark:bg-gray-700 rounded shadow-lg border border-gray-200 dark:border-gray-600 z-10">
                <p className="font-medium text-gray-900 dark:text-white mb-1">{stock.symbol} Details</p>
                <div className="space-y-1 text-gray-600 dark:text-gray-300">
                  <p>Current: ${stock.quote.currentPrice.toFixed(2)}</p>
                  <p>Open: ${stock.quote.openPrice.toFixed(2)}</p>
                  <p>High: ${stock.quote.highPrice.toFixed(2)}</p>
                  <p>Low: ${stock.quote.lowPrice.toFixed(2)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}