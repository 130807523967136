import React from 'react';
import DashboardLayout from '../../components/dashboard/Layout';
import AIChat from '../../components/dashboard/AIChat';

export default function AiChatPage() {
  return (
    <DashboardLayout>
      <div className="h-[calc(100vh-6rem)] flex flex-col">
        <div className="mb-8">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white">AI Chat</h2>
              <p className="mt-1 text-gray-600 dark:text-gray-400">
                Have a natural conversation about your data
              </p>
            </div>
          </div>
        </div>

        <div className="flex-1 bg-white dark:bg-gray-800 rounded-lg shadow-sm">
          <AIChat />
        </div>
      </div>
    </DashboardLayout>
  );
}