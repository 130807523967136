import React from 'react';
import { Link } from 'react-router-dom';
import { Brain, Mail } from 'lucide-react';

export default function Footer() {
  return (
    <footer className="bg-white dark:bg-gray-900 border-t border-gray-200 dark:border-gray-800">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="col-span-1 md:col-span-2">
            <Link to="/" className="flex items-center space-x-2">
              <Brain className="h-8 w-8 text-indigo-600 dark:text-indigo-400" />
              <span className="text-xl font-bold text-gray-900 dark:text-white">Algozi</span>
            </Link>
            <p className="mt-4 text-gray-600 dark:text-gray-400 max-w-md">
              Transform your data into actionable insights with AI-powered analytics. Make smarter decisions with automated insights and real-time analytics.
            </p>
            <div className="mt-4 flex items-center text-gray-600 dark:text-gray-400">
              <Mail className="h-5 w-5 mr-2" />
              <a href="mailto:rdavis@web3domainz.com" className="hover:text-indigo-600 dark:hover:text-indigo-400">
                rdavis@web3domainz.com
              </a>
            </div>
          </div>

          <div>
            <h3 className="text-sm font-semibold text-gray-900 dark:text-white uppercase tracking-wider">
              Legal
            </h3>
            <ul className="mt-4 space-y-4">
              <li>
                <Link to="/privacy" className="text-gray-600 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400">
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link to="/terms" className="text-gray-600 dark:text-gray-400 hover:text-indigo-600 dark:hover:text-indigo-400">
                  Terms of Service
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-8 pt-8 border-t border-gray-200 dark:border-gray-800">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <p className="text-sm text-gray-600 dark:text-gray-400">
              © {new Date().getFullYear()} Algozi. All rights reserved.
            </p>
            <p className="text-sm text-gray-600 dark:text-gray-400 mt-2 md:mt-0">
              Interested in this domain? Contact{' '}
              <a 
                href="mailto:rdavis@web3domainz.com" 
                className="text-indigo-600 dark:text-indigo-400 hover:underline"
              >
                rdavis@web3domainz.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}