import React from 'react';
import { motion } from 'framer-motion';
import { LineChart, Brain, Zap } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function Hero() {
  return (
    <div className="relative overflow-hidden bg-white dark:bg-gray-900 pt-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-20 pb-16 text-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="text-4xl sm:text-6xl font-bold text-gray-900 dark:text-white mb-6">
            Transform Your Data into
            <span className="text-indigo-600 dark:text-indigo-400"> Actionable Insights</span>
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-300 mb-8 max-w-3xl mx-auto">
            Harness the power of AI to visualize, analyze, and understand your data like never before.
            Make smarter decisions with automated insights and real-time analytics.
          </p>
          
          <div className="flex flex-col sm:flex-row justify-center gap-4 mb-12">
            <Link
              to="/signup"
              className="bg-indigo-600 text-white px-8 py-4 rounded-lg hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600 dark:text-white transition-colors text-lg font-semibold"
            >
              Start Free Trial
            </Link>
            <Link
              to="/login"
              className="border-2 border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 px-8 py-4 rounded-lg hover:border-indigo-500 dark:hover:border-indigo-400 hover:text-indigo-600 dark:hover:text-indigo-300 transition-colors text-lg font-semibold"
            >
              Sign In
            </Link>
          </div>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-4xl mx-auto">
          <div className="bg-gray-50 dark:bg-gray-800 p-6 rounded-xl">
            <LineChart className="h-8 w-8 text-indigo-600 dark:text-indigo-400 mb-4 mx-auto" />
            <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-white">Real-time Analytics</h3>
            <p className="text-gray-600 dark:text-gray-300">Monitor your metrics in real-time with interactive dashboards</p>
          </div>
          <div className="bg-gray-50 dark:bg-gray-800 p-6 rounded-xl">
            <Brain className="h-8 w-8 text-indigo-600 dark:text-indigo-400 mb-4 mx-auto" />
            <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-white">AI-Powered Insights</h3>
            <p className="text-gray-600 dark:text-gray-300">Get automated recommendations and trend analysis</p>
          </div>
          <div className="bg-gray-50 dark:bg-gray-800 p-6 rounded-xl">
            <Zap className="h-8 w-8 text-indigo-600 dark:text-indigo-400 mb-4 mx-auto" />
            <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-white">Quick Integration</h3>
            <p className="text-gray-600 dark:text-gray-300">Connect your data sources in minutes, not days</p>
          </div>
        </div>
      </div>
      
      <div className="absolute inset-0 -z-10 h-full w-full bg-white dark:bg-gray-900 bg-[linear-gradient(to_right,#f0f0f0_1px,transparent_1px),linear-gradient(to_bottom,#f0f0f0_1px,transparent_1px)] dark:bg-[linear-gradient(to_right,#2d2d2d_1px,transparent_1px),linear-gradient(to_bottom,#2d2d2d_1px,transparent_1px)] bg-[size:6rem_4rem]">
        <div className="absolute bottom-0 left-0 right-0 top-0 bg-[radial-gradient(circle_500px_at_50%_200px,#e0e7ff,transparent)] dark:bg-[radial-gradient(circle_500px_at_50%_200px,#312e81,transparent)]"></div>
      </div>
    </div>
  );
}