import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import Footer from '../components/Footer';

export default function TermsPage() {
  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <Link
          to="/"
          className="inline-flex items-center text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-300 mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to home
        </Link>

        <div className="prose dark:prose-invert max-w-none">
          <h1>Terms of Service</h1>
          <p className="lead">Last updated: {new Date().toLocaleDateString()}</p>

          <h2>1. Acceptance of Terms</h2>
          <p>
            By accessing or using Algozi's services, you agree to be bound by these Terms of Service and all applicable
            laws and regulations.
          </p>

          <h2>2. Service Description</h2>
          <p>
            Algozi provides an AI-powered data analytics platform that helps businesses analyze and visualize their data.
            Our services include:
          </p>
          <ul>
            <li>Data visualization and analytics</li>
            <li>AI-powered insights and recommendations</li>
            <li>Team collaboration features</li>
            <li>Custom dashboard creation</li>
          </ul>

          <h2>3. User Accounts</h2>
          <p>
            To use our services, you must:
          </p>
          <ul>
            <li>Create an account with accurate information</li>
            <li>Maintain the security of your account credentials</li>
            <li>Notify us immediately of any unauthorized access</li>
            <li>Be at least 18 years old</li>
          </ul>

          <h2>4. Payment Terms</h2>
          <p>
            For paid services:
          </p>
          <ul>
            <li>Payments are processed securely through Stripe</li>
            <li>Pro plan is a one-time payment</li>
            <li>Refunds are handled on a case-by-case basis</li>
            <li>Prices may change with notice</li>
          </ul>

          <h2>5. Data Usage and Privacy</h2>
          <p>
            We respect your privacy and protect your data. Our use of your data is governed by our{' '}
            <Link to="/privacy">Privacy Policy</Link>.
          </p>

          <h2>6. AI Technology</h2>
          <p>
            Our AI features:
          </p>
          <ul>
            <li>Use OpenAI's technology to process and analyze data</li>
            <li>Provide automated insights and recommendations</li>
            <li>May have limitations and should not be solely relied upon for critical decisions</li>
            <li>Are continuously improved and updated</li>
          </ul>

          <h2>7. Intellectual Property</h2>
          <p>
            You retain ownership of your data. We own:
          </p>
          <ul>
            <li>Our platform and services</li>
            <li>Our branding and trademarks</li>
            <li>Generated insights and analysis methods</li>
            <li>Platform improvements derived from usage</li>
          </ul>

          <h2>8. Limitations of Liability</h2>
          <p>
            We provide our services "as is" and are not liable for:
          </p>
          <ul>
            <li>Service interruptions or errors</li>
            <li>Data loss or corruption</li>
            <li>Decisions made based on our analytics</li>
            <li>Third-party services or integrations</li>
          </ul>

          <h2>9. Termination</h2>
          <p>
            We may terminate or suspend access to our services:
          </p>
          <ul>
            <li>For violations of these terms</li>
            <li>For abusive or fraudulent behavior</li>
            <li>To protect our users or services</li>
            <li>At our discretion with notice</li>
          </ul>

          <h2>10. Changes to Terms</h2>
          <p>
            We may update these terms with notice. Continued use of our services constitutes acceptance of updated terms.
          </p>

          <h2>11. Contact</h2>
          <p>
            For questions about these terms, contact us at{' '}
            <a href="mailto:legal@algozi.com">legal@algozi.com</a>
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}