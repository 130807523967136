import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import Chart from '../components/charts/Chart';
import { exportChartAsImage } from '../lib/utils/chartExport';
import type { ChartData } from '../types/charts';

export default function PreviewPage() {
  const location = useLocation();
  const charts: ChartData[] = location.state?.charts || [];

  const handleDownload = async (chartIndex: number) => {
    try {
      await exportChartAsImage(
        `preview-chart-${chartIndex}`,
        charts[chartIndex].title
      );
    } catch (error) {
      console.error('Error downloading chart:', error);
    }
  };

  if (!charts || charts.length === 0) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-8">
        <div className="max-w-7xl mx-auto">
          <Link
            to="/dashboard/data-sources"
            className="inline-flex items-center text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-300 mb-8"
          >
            <ArrowLeft className="h-5 w-5 mr-2" />
            Back to Data Sources
          </Link>
          
          <div className="text-center mt-16">
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
              No charts to preview
            </h2>
            <p className="text-gray-600 dark:text-gray-400">
              Please select a data source to visualize first.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 p-8">
      <div className="max-w-7xl mx-auto">
        <Link
          to="/dashboard/data-sources"
          className="inline-flex items-center text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-300 mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to Data Sources
        </Link>

        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
          Chart Preview
        </h1>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {charts.map((chart, index) => (
            <Chart
              key={index}
              chart={chart}
              index={index}
              onDownload={handleDownload}
            />
          ))}
        </div>
      </div>
    </div>
  );
}