import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from '../../store/useStore';

export const useHandlePaymentSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { updateUserPlan, addNotification } = useStore();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const upgrade = searchParams.get('upgrade');
    const plan = searchParams.get('plan');

    if (upgrade === 'success' && plan === 'pro') {
      const handleSuccess = async () => {
        try {
          await updateUserPlan('pro');
          
          addNotification({
            title: 'Welcome to Pro!',
            message: 'Your account has been upgraded successfully. You now have access to unlimited data sources and all premium features.',
            type: 'success'
          });

          // Clear URL parameters
          navigate('/dashboard', { replace: true });
        } catch (error) {
          addNotification({
            title: 'Upgrade Error',
            message: 'There was an issue upgrading your plan. Please contact support.',
            type: 'error'
          });
        }
      };

      handleSuccess();
    }
  }, [location, navigate, updateUserPlan, addNotification]);
};