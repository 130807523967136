import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { useStore } from '../store/useStore';

const steps = [
  {
    title: 'Connect Your Data',
    options: [
      'Google Analytics',
      'Salesforce',
      'MySQL Database',
      'PostgreSQL',
      'MongoDB',
      'Excel/CSV Files',
      'Amazon Redshift',
      'Shopify'
    ]
  },
  {
    title: 'Choose Your Dashboards',
    options: [
      'Sales Analytics',
      'Marketing Performance',
      'Financial Reports',
      'Customer Insights',
      'Inventory Management',
      'Website Analytics',
      'Social Media Metrics',
      'Custom Dashboard'
    ]
  },
  {
    title: 'Configure AI Assistant',
    options: [
      'Automated Insights',
      'Trend Detection',
      'Anomaly Detection',
      'Predictive Analytics',
      'Natural Language Queries',
      'Custom Reports Generation',
      'Performance Recommendations',
      'Data Story Generation'
    ]
  }
];

export default function GetStartedPage() {
  const navigate = useNavigate();
  const { setSetupData } = useStore();
  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const [selections, setSelections] = useState({
    dataSources: [] as string[],
    dashboards: [] as string[],
    aiPreferences: [] as string[]
  });

  const handleOptionToggle = (option: string) => {
    const category = step === 1 ? 'dataSources' : step === 2 ? 'dashboards' : 'aiPreferences';
    setSelections(prev => {
      const current = prev[category];
      const updated = current.includes(option)
        ? current.filter(item => item !== option)
        : [...current, option];
      return { ...prev, [category]: updated };
    });
    setError('');
  };

  const handleNext = () => {
    const category = step === 1 ? 'dataSources' : step === 2 ? 'dashboards' : 'aiPreferences';
    if (selections[category].length === 0) {
      setError('Please select at least one option to continue');
      return;
    }

    if (step === 3) {
      // Save selections and navigate to signup
      setSetupData(selections);
      navigate('/signup', { state: { setupData: selections } });
    } else {
      setStep(prev => prev + 1);
      setError('');
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(prev => prev - 1);
    } else {
      navigate('/');
    }
  };

  const currentStep = steps[step - 1];
  const currentSelections = step === 1 ? selections.dataSources 
    : step === 2 ? selections.dashboards 
    : selections.aiPreferences;

  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <button
        onClick={handleBack}
        className="mb-8 flex items-center text-gray-600 hover:text-gray-900"
      >
        <ArrowLeft className="h-5 w-5 mr-2" />
        Back
      </button>

      <div className="max-w-3xl mx-auto">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-gray-900">Get Started with Algozi</h2>
          <p className="mt-2 text-gray-600">Step {step} of 3: {currentStep.title}</p>
        </div>

        {/* Progress bar */}
        <div className="mb-8">
          <div className="flex items-center justify-between mb-2">
            {[1, 2, 3].map((num) => (
              <div
                key={num}
                className={`flex items-center ${
                  num < step ? 'text-indigo-600' : num === step ? 'text-gray-900' : 'text-gray-400'
                }`}
              >
                <span className={`h-8 w-8 rounded-full flex items-center justify-center border-2 ${
                  num < step ? 'border-indigo-600 bg-indigo-600 text-white' 
                  : num === step ? 'border-gray-900' 
                  : 'border-gray-300'
                }`}>
                  {num}
                </span>
                <span className="ml-2 font-medium">{steps[num - 1].title}</span>
              </div>
            ))}
          </div>
          <div className="h-2 bg-gray-200 rounded-full">
            <div
              className="h-full bg-indigo-600 rounded-full transition-all duration-300"
              style={{ width: `${((step - 1) / 2) * 100}%` }}
            />
          </div>
        </div>

        {error && (
          <div className="mb-4 p-4 bg-red-50 text-red-700 rounded-md">
            {error}
          </div>
        )}

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-8">
          {currentStep.options.map((option) => (
            <button
              key={option}
              onClick={() => handleOptionToggle(option)}
              className={`p-4 rounded-lg border-2 text-left ${
                currentSelections.includes(option)
                  ? 'border-indigo-600 bg-indigo-50'
                  : 'border-gray-200 hover:border-indigo-300'
              }`}
            >
              <h3 className="font-medium">{option}</h3>
            </button>
          ))}
        </div>

        <div className="flex justify-end">
          <button
            onClick={handleNext}
            className="px-6 py-3 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700"
          >
            {step === 3 ? 'Complete Setup' : 'Next Step'}
          </button>
        </div>
      </div>
    </div>
  );
}