import React from 'react';
import { Database, Trash2, BarChart } from 'lucide-react';
import type { DataSource } from '../../lib/types/dataSource';

interface DataSourceListProps {
  dataSources: DataSource[];
  onRemove: (id: string) => void;
  onView: (id: string) => void;
}

export default function DataSourceList({ dataSources, onRemove, onView }: DataSourceListProps) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      {dataSources.map((source) => (
        <div 
          key={source.id} 
          className="bg-white dark:bg-gray-800 rounded-lg shadow-sm overflow-hidden"
        >
          <div className="p-6">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <div className="p-2 bg-indigo-50 dark:bg-indigo-900/50 rounded-lg">
                  <Database className="h-6 w-6 text-indigo-600 dark:text-indigo-400" />
                </div>
                <div className="ml-3">
                  <h3 className="text-lg font-medium text-gray-900 dark:text-white">
                    {source.name}
                  </h3>
                  <p className="text-sm text-gray-500 dark:text-gray-400">
                    {source.data.length.toLocaleString()} rows
                  </p>
                </div>
              </div>
            </div>
            <div className="text-sm text-gray-500 dark:text-gray-400 mb-4">
              Last updated: {new Date(source.lastSync).toLocaleDateString()}
            </div>
            <div className="flex items-center justify-between">
              <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                source.status === 'connected'
                  ? 'bg-green-100 text-green-800 dark:bg-green-900 dark:text-green-200'
                  : source.status === 'error'
                  ? 'bg-red-100 text-red-800 dark:bg-red-900 dark:text-red-200'
                  : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900 dark:text-yellow-200'
              }`}>
                {source.status.charAt(0).toUpperCase() + source.status.slice(1)}
              </span>
              <div className="flex space-x-2">
                <button
                  onClick={() => onView(source.id)}
                  className="p-2 text-indigo-600 hover:text-indigo-700 dark:text-indigo-400 dark:hover:text-indigo-300 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                  title="View charts"
                >
                  <BarChart className="h-5 w-5" />
                </button>
                <button
                  onClick={() => onRemove(source.id)}
                  className="p-2 text-gray-400 hover:text-red-600 dark:hover:text-red-400 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-700"
                  title="Remove data source"
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}