/**
 * Generates a RFC4122 version 4 compliant UUID
 * Uses a fallback implementation for browsers that don't support crypto.randomUUID
 */
export function generateUUID(): string {
  // Try using crypto.randomUUID() first
  if (typeof crypto !== 'undefined' && typeof crypto.randomUUID === 'function') {
    return crypto.randomUUID();
  }

  // Fallback implementation using crypto.getRandomValues
  const getRandomHex = (c: string) => {
    const r = crypto.getRandomValues(new Uint8Array(1))[0];
    const v = c === 'x' ? r & 0x0f | 0x40 : r & 0x3f | 0x80;
    return v.toString(16);
  };

  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, getRandomHex);
}