import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSession } from '@supabase/auth-helpers-react';
import { useStore } from '../../store/useStore';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

export default function ProtectedRoute({ children }: ProtectedRouteProps) {
  const session = useSession();
  const location = useLocation();
  const { isAuthenticated } = useStore();

  if (!session && !isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
}