import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Check, AlertCircle, ArrowLeft, Crown } from 'lucide-react';
import { useStore } from '../store/useStore';
import Footer from '../components/Footer';

const features = {
  basic: [
    'One data source',
    'Basic analytics',
    'Standard visualizations',
    'Email support',
    '7-day data history',
    'Basic templates',
    'Community access'
  ],
  pro: [
    'Unlimited Data Sources',
    'Advanced AI Analytics',
    'Team Collaboration'
  ]
};

export default function PricingPage() {
  const navigate = useNavigate();
  const { isAuthenticated } = useStore();
  const [error, setError] = useState('');

  const handleGetStarted = () => {
    navigate('/signup');
  };

  const handleUpgrade = () => {
    if (!isAuthenticated) {
      navigate('/login', { state: { from: '/pricing' } });
      return;
    }
    navigate('/checkout', { 
      state: { 
        plan: 'pro',
        returnUrl: '/pricing'
      }
    });
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <Link
          to="/"
          className="inline-flex items-center text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-300 mb-8"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Back to home
        </Link>

        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-gray-900 dark:text-white mb-4">
            Simple, transparent pricing
          </h1>
          <p className="text-xl text-gray-600 dark:text-gray-400">
            Start for free, upgrade when you need more power
          </p>
        </div>

        {error && (
          <div className="max-w-3xl mx-auto mb-8 p-4 bg-red-50 dark:bg-red-900/50 rounded-lg flex items-center text-red-700 dark:text-red-200">
            <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
            <p>{error}</p>
          </div>
        )}

        <div className="max-w-7xl mx-auto grid grid-cols-1 gap-8 lg:grid-cols-2">
          {/* Basic Plan */}
          <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-lg overflow-hidden">
            <div className="p-8">
              <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Basic</h2>
              <p className="mt-2 text-gray-600 dark:text-gray-400">Perfect for getting started</p>
              <p className="mt-4">
                <span className="text-4xl font-bold text-gray-900 dark:text-white">$0</span>
                <span className="text-gray-600 dark:text-gray-400">/forever</span>
              </p>

              <ul className="mt-8 space-y-4">
                {features.basic.map((feature) => (
                  <li key={feature} className="flex items-start">
                    <Check className="h-5 w-5 text-green-500 dark:text-green-400 mt-0.5 mr-3" />
                    <span className="text-gray-600 dark:text-gray-300">{feature}</span>
                  </li>
                ))}
              </ul>

              <button
                onClick={handleGetStarted}
                className="mt-8 w-full py-3 px-4 rounded-lg font-semibold bg-gray-900 dark:bg-gray-700 text-white hover:bg-gray-800 dark:hover:bg-gray-600 transition-colors"
              >
                Get Started Free
              </button>
            </div>
          </div>

          {/* Pro Plan */}
          <div className="bg-gradient-to-br from-indigo-500 to-purple-600 rounded-2xl shadow-lg overflow-hidden">
            <div className="p-8">
              <div className="flex items-center space-x-2">
                <Crown className="h-6 w-6 text-white" />
                <h2 className="text-2xl font-bold text-white">Pro</h2>
              </div>
              <p className="mt-2 text-indigo-100">Unlimited power and features</p>
              <p className="mt-4">
                <span className="text-4xl font-bold text-white">$7.99</span>
                <span className="text-indigo-100">/one-time</span>
              </p>

              <ul className="mt-8 space-y-4">
                {features.pro.map((feature) => (
                  <li key={feature} className="flex items-start">
                    <Check className="h-5 w-5 text-white mt-0.5 mr-3" />
                    <span className="text-white">{feature}</span>
                  </li>
                ))}
              </ul>

              <button
                onClick={handleUpgrade}
                className="mt-8 w-full py-3 px-4 rounded-lg font-semibold bg-white text-indigo-600 hover:bg-indigo-50 transition-colors"
              >
                {isAuthenticated ? 'Upgrade to Pro' : 'Get Pro Access'}
              </button>
            </div>
          </div>
        </div>

        <div className="max-w-3xl mx-auto mt-16 text-center">
          <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-4">
            Frequently Asked Questions
          </h3>
          <div className="mt-8 grid gap-6">
            <div className="text-left">
              <h4 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                What's included in the free plan?
              </h4>
              <p className="text-gray-600 dark:text-gray-400">
                The free plan includes one data source, basic analytics features, and standard visualizations. It's perfect for individuals or small teams just getting started with data analytics.
              </p>
            </div>
            <div className="text-left">
              <h4 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                Is the Pro plan really a one-time payment?
              </h4>
              <p className="text-gray-600 dark:text-gray-400">
                Yes! Pay once and get lifetime access to all Pro features. No recurring fees or hidden charges.
              </p>
            </div>
            <div className="text-left">
              <h4 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                Can I upgrade anytime?
              </h4>
              <p className="text-gray-600 dark:text-gray-400">
                Absolutely! You can upgrade to Pro at any time. Your existing data and settings will be preserved.
              </p>
            </div>
            <div className="text-left">
              <h4 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
                What payment methods do you accept?
              </h4>
              <p className="text-gray-600 dark:text-gray-400">
                We accept all major credit cards through our secure payment processor, Stripe. Your payment information is never stored on our servers.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}