import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertCircle } from 'lucide-react';
import DashboardLayout from '../../components/dashboard/Layout';
import { useStore } from '../../store/useStore';
import FileUploader from '../../components/datasources/FileUploader';
import DataSourceList from '../../components/datasources/DataSourceList';
import UpgradeModal from '../../components/datasources/UpgradeModal';
import { processFile } from '../../lib/fileProcessing';
import { generateCharts } from '../../lib/chartAnalysis';
import { generateUUID } from '../../lib/utils/uuid';

export default function DataSourcesPage() {
  const navigate = useNavigate();
  const { userData, connectedDataSources, addDataSource, removeDataSource } = useStore();
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState('');
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const handleFileSelect = async (file: File) => {
    if (userData?.planId === 'basic' && connectedDataSources.length >= 1) {
      setShowUpgradeModal(true);
      return;
    }

    setIsUploading(true);
    setError('');

    try {
      const data = await processFile(file);
      
      // Add data source
      const newSource = {
        id: generateUUID(),
        name: file.name,
        type: 'spreadsheet',
        status: 'connected',
        lastSync: new Date().toISOString(),
        data
      };
      
      addDataSource(newSource);

      // Generate initial charts
      const charts = generateCharts(data);
      
      // Navigate to view page with charts
      navigate(`/dashboard/view/${newSource.id}`, { 
        state: { charts }
      });
    } catch (err) {
      console.error('Error processing file:', err);
      setError(err instanceof Error ? err.message : 'Failed to process file');
    } finally {
      setIsUploading(false);
    }
  };

  const handleRemoveDataSource = (id: string) => {
    removeDataSource(id);
  };

  const handleViewCharts = (id: string) => {
    const dataSource = connectedDataSources.find(ds => ds.id === id);
    if (dataSource?.data) {
      const charts = generateCharts(dataSource.data);
      navigate(`/dashboard/view/${id}`, { state: { charts } });
    }
  };

  return (
    <DashboardLayout>
      <div className="max-w-7xl mx-auto">
        <div className="mb-8">
          <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Data Sources</h2>
          <p className="mt-1 text-gray-600 dark:text-gray-400">
            Upload and manage your data files
          </p>
        </div>

        {error && (
          <div className="mb-6 p-4 bg-red-50 dark:bg-red-900/50 rounded-lg flex items-center text-red-700 dark:text-red-200">
            <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
            <p>{error}</p>
          </div>
        )}

        <div className="mb-12">
          <FileUploader
            onFileSelect={handleFileSelect}
            isLoading={isUploading}
          />
        </div>

        {connectedDataSources.length > 0 ? (
          <DataSourceList
            dataSources={connectedDataSources}
            onRemove={handleRemoveDataSource}
            onView={handleViewCharts}
          />
        ) : (
          <div className="text-center py-12 bg-white dark:bg-gray-800 rounded-lg">
            <p className="text-gray-500 dark:text-gray-400">
              No data sources connected. Upload your first file to get started!
            </p>
          </div>
        )}

        {showUpgradeModal && (
          <UpgradeModal onClose={() => setShowUpgradeModal(false)} />
        )}
      </div>
    </DashboardLayout>
  );
}