import React from 'react';
import { Bot, AlertCircle } from 'lucide-react';
import type { ChatMessage } from '../../lib/chat/messageProcessor';

interface ChatMessageProps {
  message: ChatMessage;
}

export default function ChatMessage({ message }: ChatMessageProps) {
  return (
    <div
      className={`flex items-start ${
        message.type === 'user' ? 'justify-end' : 'justify-start'
      }`}
    >
      {message.type !== 'user' && (
        <div className={`h-8 w-8 rounded-full flex items-center justify-center mr-4 ${
          message.type === 'assistant' 
            ? 'bg-indigo-600 text-white dark:bg-indigo-500'
            : 'bg-red-600 text-white'
        }`}>
          {message.type === 'assistant' ? (
            <Bot className="h-5 w-5" />
          ) : (
            <AlertCircle className="h-5 w-5" />
          )}
        </div>
      )}
      <div className={`rounded-lg p-4 max-w-3xl whitespace-pre-wrap ${
        message.type === 'user'
          ? 'bg-indigo-600 text-white dark:bg-indigo-500'
          : message.type === 'error'
          ? 'bg-red-50 text-red-700 dark:bg-red-900/50 dark:text-red-200'
          : 'bg-gray-100 text-gray-900 dark:bg-gray-700 dark:text-gray-100'
      }`}>
        {formatMessage(message.content)}
      </div>
    </div>
  );
}

function formatMessage(content: string): React.ReactNode {
  // Split content into paragraphs and format them
  return content.split('\n').map((paragraph, index) => {
    if (paragraph.trim().startsWith('•') || paragraph.trim().startsWith('-')) {
      return (
        <div key={index} className="flex items-start space-x-2 mb-2">
          <span className="text-indigo-600 dark:text-indigo-400">•</span>
          <span>{paragraph.trim().substring(1)}</span>
        </div>
      );
    }
    return <p key={index} className="mb-2">{paragraph}</p>;
  });
}