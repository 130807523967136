export interface StockSearchResult {
  symbol: string;
  name: string;
  type: string;
  region: string;
}

export interface StockQuote {
  currentPrice: number;
  highPrice: number;
  lowPrice: number;
  openPrice: number;
  previousClose: number;
  volume: number;
  change: number;
  changePercent: number;
}

export interface CompanyProfile {
  name: string;
  description: string;
  exchange: string;
  sector: string;
  industry: string;
  marketCap: number;
}

export interface ChartData {
  date: string;
  price: number;
  volume: number;
}

export interface StockData {
  quote: StockQuote;
  profile: CompanyProfile;
  chartData: ChartData[];
  trends: {
    daily: number;
    weekly: number;
    monthly: number;
  };
}

export const popularStocks = [
  { symbol: 'AAPL', name: 'Apple Inc.' },
  { symbol: 'MSFT', name: 'Microsoft Corporation' },
  { symbol: 'GOOGL', name: 'Alphabet Inc.' },
  { symbol: 'AMZN', name: 'Amazon.com Inc.' },
  { symbol: 'META', name: 'Meta Platforms Inc.' },
  { symbol: 'TSLA', name: 'Tesla Inc.' },
  { symbol: 'NVDA', name: 'NVIDIA Corporation' },
  { symbol: 'JPM', name: 'JPMorgan Chase & Co.' },
  { symbol: 'V', name: 'Visa Inc.' },
  { symbol: 'JNJ', name: 'Johnson & Johnson' }
] as const;