import { create, all } from 'mathjs';
const math = create(all);

export async function analyzeDataset(data: any[]): Promise<DataAnalysis> {
  if (!data || !Array.isArray(data) || data.length === 0) {
    throw new Error('No data provided for analysis');
  }

  const firstRow = data[0];
  if (!firstRow || typeof firstRow !== 'object') {
    throw new Error('Invalid data structure: Expected array of objects');
  }

  const columns = Object.keys(firstRow);
  if (columns.length === 0) {
    throw new Error('No columns found in data');
  }

  console.log('Starting dataset analysis:', {
    rowCount: data.length,
    columnCount: columns.length
  });

  // Analyze column types and statistics
  const columnTypes = detectColumnTypes(data, columns);
  const statistics = calculateColumnStatistics(data, columnTypes);
  const correlations = findCorrelations(data, columnTypes);

  console.log('Analysis complete:', {
    columnTypes,
    statisticsSummary: Object.keys(statistics).length,
    correlationsFound: correlations.length
  });

  return {
    summary: {
      rowCount: data.length,
      columnCount: columns.length,
      columnTypes
    },
    statistics,
    correlations,
    sampleData: data.slice(0, 5)
  };
}

function detectColumnTypes(data: any[], columns: string[]): Record<string, ColumnType> {
  const types: Record<string, ColumnType> = {};

  columns.forEach(column => {
    const values = data.map(row => row[column]).filter(v => v != null);
    
    if (values.length === 0) {
      types[column] = 'unknown';
      return;
    }

    if (values.every(v => !isNaN(Number(v)))) {
      types[column] = 'number';
    } else if (values.every(v => !isNaN(Date.parse(String(v))))) {
      types[column] = 'date';
    } else {
      types[column] = 'string';
    }
  });

  return types;
}

function calculateColumnStatistics(
  data: any[],
  columnTypes: Record<string, ColumnType>
): Record<string, Statistics> {
  const stats: Record<string, Statistics> = {};

  Object.entries(columnTypes).forEach(([column, type]) => {
    const values = data.map(row => row[column]).filter(v => v != null);
    
    if (type === 'number') {
      const numbers = values.map(Number);
      stats[column] = {
        mean: math.mean(numbers),
        stdDev: math.std(numbers),
        min: math.min(numbers),
        max: math.max(numbers),
        uniqueCount: new Set(values).size
      };
    } else {
      stats[column] = {
        uniqueCount: new Set(values).size,
        mostCommon: findMostCommonValues(values)
      };
    }
  });

  return stats;
}

function findCorrelations(
  data: any[],
  columnTypes: Record<string, ColumnType>
): Array<{ column1: string; column2: string; correlation: number }> {
  const numericColumns = Object.entries(columnTypes)
    .filter(([_, type]) => type === 'number')
    .map(([col]) => col);

  const correlations = [];

  for (let i = 0; i < numericColumns.length; i++) {
    for (let j = i + 1; j < numericColumns.length; j++) {
      const col1 = numericColumns[i];
      const col2 = numericColumns[j];

      const values1 = data.map(row => Number(row[col1]));
      const values2 = data.map(row => Number(row[col2]));

      const correlation = calculateCorrelation(values1, values2);
      
      if (!isNaN(correlation) && Math.abs(correlation) > 0.5) {
        correlations.push({
          column1: col1,
          column2: col2,
          correlation
        });
      }
    }
  }

  return correlations;
}

function calculateCorrelation(x: number[], y: number[]): number {
  const n = x.length;
  if (n === 0) return 0;

  const sum1 = math.sum(x);
  const sum2 = math.sum(y);
  const sum1Sq = math.sum(x.map(v => v * v));
  const sum2Sq = math.sum(y.map(v => v * v));
  const pSum = math.sum(x.map((v, i) => v * y[i]));
  
  const num = pSum - (sum1 * sum2 / n);
  const den = math.sqrt((sum1Sq - sum1 * sum1 / n) * (sum2Sq - sum2 * sum2 / n));
  
  return den === 0 ? 0 : num / den;
}

function findMostCommonValues(values: any[]): { value: any; count: number }[] {
  const counts = new Map<any, number>();
  values.forEach(value => {
    counts.set(value, (counts.get(value) || 0) + 1);
  });

  return Array.from(counts.entries())
    .sort((a, b) => b[1] - a[1])
    .slice(0, 3)
    .map(([value, count]) => ({ value, count }));
}

export type { DataAnalysis } from './types';