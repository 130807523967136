import axios from 'axios';

const API_KEY = import.meta.env.VITE_ALPHA_VANTAGE_API_KEY;
const BASE_URL = 'https://www.alphavantage.co/query';

if (!API_KEY) {
  console.error('Alpha Vantage API key is missing! Please check your .env file.');
}

export const alphaVantageClient = axios.create({
  baseURL: BASE_URL,
  params: {
    apikey: API_KEY
  },
  timeout: 15000
});

// Add response interceptor for better error handling
alphaVantageClient.interceptors.response.use(
  response => {
    // Check for API limit message
    if (response.data?.Note?.includes('API call frequency')) {
      throw new Error('API rate limit exceeded. Please try again in a minute.');
    }
    // Check for error messages
    if (response.data?.['Error Message']) {
      throw new Error(response.data['Error Message']);
    }
    // Check for information messages
    if (response.data?.Information) {
      throw new Error(response.data.Information);
    }
    return response;
  },
  error => {
    if (error.response?.status === 429) {
      throw new Error('API rate limit exceeded. Please try again in a minute.');
    }
    if (error.code === 'ECONNABORTED') {
      throw new Error('Request timed out. Please try again.');
    }
    throw error;
  }
);