import React, { useState, useEffect } from 'react';
import DashboardLayout from '../../components/dashboard/Layout';
import { AlertCircle, Loader2, Info } from 'lucide-react';
import { marketSectors, fetchSectorData, predictTrend } from '../../lib/marketData';
import { fetchIndustryNews, NewsArticle } from '../../lib/newsApi';
import MarketSectorCard from '../../components/analytics/MarketSectorCard';
import SectorAnalysis from '../../components/analytics/SectorAnalysis';

export default function AnalyticsPage() {
  const [selectedSector, setSelectedSector] = useState<string | null>(null);
  const [sectorData, setSectorData] = useState<any[]>([]);
  const [sectorNews, setSectorNews] = useState<NewsArticle[]>([]);
  const [predictions, setPredictions] = useState<{ prediction: number; confidence: number } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    async function loadSectorData() {
      if (!selectedSector) return;
      
      setIsLoading(true);
      setError('');

      try {
        // Load data in parallel
        const [data, news] = await Promise.all([
          fetchSectorData(selectedSector).catch(err => {
            console.error('Error fetching sector data:', err);
            return [];
          }),
          fetchIndustryNews(selectedSector).catch(err => {
            console.error('Error fetching news:', err);
            return [];
          })
        ]);

        if (data.length === 0) {
          setError('Unable to load market data. Using demo data instead.');
        }

        setSectorData(data);
        setSectorNews(news);

        // Calculate predictions
        const trendData = data.map(item => item.trends.daily);
        const prediction = predictTrend(selectedSector, trendData);
        setPredictions(prediction);
      } catch (err) {
        console.error('Error loading sector data:', err);
        setError('Unable to load market data. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    }

    loadSectorData();
  }, [selectedSector]);

  return (
    <DashboardLayout>
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Market Analytics</h2>
        <p className="mt-1 text-gray-600 dark:text-gray-400">
          Analyze market trends and get AI-powered predictions across different sectors.
        </p>
        
        {/* AI Disclaimer */}
        <div className="mt-4 p-4 bg-blue-50 dark:bg-blue-900/20 rounded-lg flex items-start">
          <Info className="h-5 w-5 text-blue-600 dark:text-blue-400 mt-0.5 mr-2 flex-shrink-0" />
          <div className="text-sm text-blue-700 dark:text-blue-200">
            <p className="font-medium mb-1">AI-Powered Analysis</p>
            <p>
              The analysis and predictions provided are generated using artificial intelligence. 
              While we strive for accuracy, these insights should be used as one of many tools 
              in your decision-making process. Always verify critical information and consult 
              with financial professionals for investment decisions.
            </p>
          </div>
        </div>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-yellow-50 dark:bg-yellow-900 rounded-lg flex items-center text-yellow-700 dark:text-yellow-100">
          <AlertCircle className="h-5 w-5 mr-2" />
          <p>{error}</p>
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        {marketSectors.map(sector => (
          <MarketSectorCard
            key={sector.id}
            sector={sector}
            data={selectedSector === sector.id ? sectorData : []}
            onSelect={() => setSelectedSector(sector.id)}
            selected={selectedSector === sector.id}
          />
        ))}
      </div>

      {isLoading ? (
        <div className="flex items-center justify-center h-64">
          <Loader2 className="h-8 w-8 text-indigo-600 dark:text-indigo-400 animate-spin" />
        </div>
      ) : selectedSector && sectorData.length > 0 && predictions ? (
        <SectorAnalysis
          sectorData={sectorData}
          predictions={predictions}
          news={sectorNews}
        />
      ) : (
        <div className="text-center py-12 bg-white dark:bg-gray-800 rounded-lg">
          <p className="text-gray-600 dark:text-gray-400">
            Select a market sector above to view detailed analysis and predictions
          </p>
        </div>
      )}
    </DashboardLayout>
  );
}