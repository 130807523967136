import OpenAI from 'openai';
import { analyzeDataset } from './analysis/dataAnalyzer';
import type { DataAnalysis } from './analysis/types';

const apiKey = import.meta.env.VITE_OPENAI_API_KEY;

if (!apiKey) {
  console.warn('OpenAI API key not configured. AI features will be unavailable.');
}

const openai = new OpenAI({
  apiKey,
  dangerouslyAllowBrowser: true
});

export async function analyzeData(data: any[], userQuery?: string): Promise<string> {
  if (!apiKey) {
    throw new Error('OpenAI API key not configured. Please add your API key to the .env file.');
  }

  if (!data || !Array.isArray(data) || data.length === 0) {
    throw new Error('No data provided for analysis');
  }

  try {
    console.log('Starting data analysis...', {
      rowCount: data.length,
      sampleRow: data[0]
    });
    
    // First, analyze the dataset
    const analysis = await analyzeDataset(data);
    console.log('Dataset analysis completed');

    // Generate prompt based on user query or default analysis
    const prompt = userQuery 
      ? generateQueryPrompt(analysis, userQuery)
      : generateAnalysisPrompt(analysis);
    
    console.log('Generated analysis prompt');

    // Get AI insights
    const response = await openai.chat.completions.create({
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: `You are a data analyst providing clear, actionable insights. Focus on patterns, trends, and practical recommendations. Use bullet points and clear sections. Be specific and reference actual numbers from the data. Always format your response in a clear, structured way.

When answering specific questions:
- Focus on the exact information requested
- Provide relevant statistics and numbers
- Explain any patterns or trends related to the question
- Suggest actionable insights based on the findings

For general analysis:
- Start with key findings and important patterns
- Break down significant trends with supporting numbers
- Highlight any unusual patterns or outliers
- Conclude with actionable recommendations`
        },
        {
          role: "user",
          content: prompt
        }
      ],
      temperature: 0.7,
      max_tokens: 1500
    });

    console.log('OpenAI response received');
    return response.choices[0].message.content || 'No analysis available.';
  } catch (error) {
    console.error('OpenAI API error:', error);
    
    if (error instanceof Error) {
      if (error.message.includes('401')) {
        throw new Error('Invalid OpenAI API key. Please check your configuration.');
      }
      if (error.message.includes('429')) {
        throw new Error('OpenAI API rate limit exceeded. Please try again later.');
      }
      if (error.message.includes('No data provided')) {
        throw new Error('Please upload a valid data file first.');
      }
      throw error;
    }
    
    throw new Error('Failed to generate analysis. Please try again later.');
  }
}

function generateQueryPrompt(analysis: DataAnalysis, query: string): string {
  return `Analyze this dataset based on the following question: "${query}"

Dataset Overview:
- Total Rows: ${analysis.summary.rowCount}
- Total Columns: ${analysis.summary.columnCount}
- Column Types: ${Object.entries(analysis.summary.columnTypes)
    .map(([col, type]) => `${col} (${type})`)
    .join(', ')}

Key Statistics:
${Object.entries(analysis.statistics)
  .map(([col, stats]) => {
    if (stats.mean !== undefined) {
      return `${col}:
  - Average: ${stats.mean.toFixed(2)}
  - Min: ${stats.min}
  - Max: ${stats.max}
  - Unique Values: ${stats.uniqueCount}`;
    }
    return `${col}:
  - Unique Values: ${stats.uniqueCount}
  - Most Common: ${stats.mostCommon?.map(v => `${v.value} (${v.count} times)`).join(', ') || 'N/A'}`;
  })
  .join('\n')}

Notable Correlations:
${analysis.correlations.length > 0 
  ? analysis.correlations.map(c => `- ${c.column1} & ${c.column2}: ${c.correlation.toFixed(2)} correlation`).join('\n')
  : '- No significant correlations found'}

Sample Data (first 5 rows):
${JSON.stringify(analysis.sampleData, null, 2)}

Please provide a detailed answer to the question, focusing on relevant data points and insights. Include specific numbers and trends from the data to support your analysis.`;
}

function generateAnalysisPrompt(analysis: DataAnalysis): string {
  return `Analyze this dataset and provide comprehensive insights:

Dataset Overview:
- Total Rows: ${analysis.summary.rowCount}
- Total Columns: ${analysis.summary.columnCount}
- Column Types: ${Object.entries(analysis.summary.columnTypes)
    .map(([col, type]) => `${col} (${type})`)
    .join(', ')}

Key Statistics:
${Object.entries(analysis.statistics)
  .map(([col, stats]) => {
    if (stats.mean !== undefined) {
      return `${col}:
  - Average: ${stats.mean.toFixed(2)}
  - Min: ${stats.min}
  - Max: ${stats.max}
  - Unique Values: ${stats.uniqueCount}`;
    }
    return `${col}:
  - Unique Values: ${stats.uniqueCount}
  - Most Common: ${stats.mostCommon?.map(v => `${v.value} (${v.count} times)`).join(', ') || 'N/A'}`;
  })
  .join('\n')}

Notable Correlations:
${analysis.correlations.length > 0 
  ? analysis.correlations.map(c => `- ${c.column1} & ${c.column2}: ${c.correlation.toFixed(2)} correlation`).join('\n')
  : '- No significant correlations found'}

Sample Data (first 5 rows):
${JSON.stringify(analysis.sampleData, null, 2)}

Please provide a comprehensive analysis including:
1. Key patterns and trends in the data
2. Notable statistics and outliers
3. Significant correlations and relationships
4. Actionable recommendations based on the analysis
5. Potential areas for further investigation

Format your response with clear sections and bullet points. Focus on insights that would be valuable for business decision-making.`;
}