import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Loader2, AlertCircle, ArrowLeft } from 'lucide-react';
import { useStore } from '../store/useStore';
import { redirectToStripeCheckout } from '../lib/stripe';

export default function CheckoutPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { userData } = useStore();
  const [error, setError] = useState('');

  useEffect(() => {
    const initCheckout = async () => {
      try {
        const { plan, returnUrl } = location.state || {};
        
        if (!plan) {
          navigate('/pricing');
          return;
        }

        if (!userData?.email || !userData?.id) {
          navigate('/login', { 
            state: { 
              from: location, 
              plan,
              returnUrl 
            }
          });
          return;
        }

        // Construct success URL with plan info
        const successUrl = `${window.location.origin}/dashboard?upgrade=success&plan=${plan.id}`;
        const cancelUrl = `${window.location.origin}/pricing?canceled=true`;

        // Redirect to Stripe Checkout
        await redirectToStripeCheckout(
          userData.email,
          userData.id,
          successUrl,
          cancelUrl
        );
      } catch (err) {
        console.error('Checkout error:', err);
        setError(err instanceof Error ? err.message : 'Failed to initialize checkout');
      }
    };

    initCheckout();
  }, [location, userData, navigate]);

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center p-4">
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg p-8 max-w-md w-full">
          <div className="flex items-center text-red-600 dark:text-red-400 mb-4">
            <AlertCircle className="h-6 w-6 mr-2" />
            <h2 className="text-lg font-semibold">Checkout Error</h2>
          </div>
          <p className="text-gray-600 dark:text-gray-400 mb-6">{error}</p>
          <div className="flex space-x-4">
            <button
              onClick={() => navigate(-1)}
              className="flex items-center text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
            >
              <ArrowLeft className="h-5 w-5 mr-2" />
              Go Back
            </button>
            <button
              onClick={() => navigate('/pricing')}
              className="flex-1 bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600"
            >
              Return to Pricing
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex items-center justify-center p-4">
      <div className="text-center">
        <Loader2 className="h-8 w-8 animate-spin text-indigo-600 dark:text-indigo-400 mx-auto" />
        <p className="mt-4 text-gray-600 dark:text-gray-400">Redirecting to secure checkout...</p>
      </div>
    </div>
  );
}