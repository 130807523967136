import { useStore } from '../store/useStore';

const CHECKOUT_URL = 'https://buy.stripe.com/cN2bM4dZ871n4pO8ww';

export const redirectToStripeCheckout = async (
  email?: string,
  userId?: string,
  successUrl: string = `${window.location.origin}/dashboard?upgrade=success&plan=pro`,
  cancelUrl: string = `${window.location.origin}/pricing?canceled=true`
) => {
  try {
    const store = useStore.getState();
    
    if (!email) {
      throw new Error('Email is required for checkout');
    }

    // Add notification about redirect
    store.addNotification({
      title: 'Redirecting to Checkout',
      message: 'You are being redirected to our secure payment page.',
      type: 'info'
    });

    // Direct redirect to Stripe hosted checkout page
    window.location.href = CHECKOUT_URL;
  } catch (err) {
    console.error('Stripe checkout error:', err);
    const store = useStore.getState();
    store.addNotification({
      title: 'Checkout Error',
      message: err instanceof Error ? err.message : 'Failed to start checkout process',
      type: 'error'
    });
    throw err;
  }
};

export const handleSuccessfulPayment = async () => {
  const store = useStore.getState();
  const { userData } = store;

  if (!userData) {
    console.error('No user data found when handling successful payment');
    return;
  }

  try {
    await store.updateUserPlan('pro');
    
    store.addNotification({
      title: 'Payment Successful',
      message: 'Thank you for upgrading to Pro! You now have access to unlimited data sources.',
      type: 'success'
    });
  } catch (err) {
    console.error('Error updating plan after payment:', err);
    store.addNotification({
      title: 'Plan Upgrade Error',
      message: 'There was an issue upgrading your plan. Please contact support.',
      type: 'error'
    });
  }
};