import * as XLSX from 'xlsx';
import Papa from 'papaparse';

export async function processFile(file: File): Promise<any[]> {
  const fileType = file.name.split('.').pop()?.toLowerCase();

  if (fileType === 'csv') {
    return processCsvFile(file);
  } else if (fileType === 'xlsx' || fileType === 'xls') {
    return processExcelFile(file);
  } else {
    throw new Error('Please upload a CSV or Excel file');
  }
}

function processCsvFile(file: File): Promise<any[]> {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true, // Treat first row as headers
      dynamicTyping: true, // Automatically convert numbers
      skipEmptyLines: true,
      complete: (results) => {
        if (results.errors.length > 0) {
          console.error('CSV parsing errors:', results.errors);
          reject(new Error('Error parsing CSV file: ' + results.errors[0].message));
          return;
        }

        if (!results.data || results.data.length === 0) {
          reject(new Error('No data found in file'));
          return;
        }

        // Validate data structure
        const data = results.data as any[];
        if (!validateDataStructure(data)) {
          reject(new Error('Invalid data structure in CSV file'));
          return;
        }

        resolve(data);
      },
      error: (error) => {
        console.error('CSV parsing error:', error);
        reject(new Error('Failed to parse CSV file'));
      }
    });
  });
}

async function processExcelFile(file: File): Promise<any[]> {
  try {
    const buffer = await file.arrayBuffer();
    const workbook = XLSX.read(buffer, { type: 'array' });
    
    // Get first sheet
    const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
    if (!firstSheet) {
      throw new Error('No sheets found in Excel file');
    }

    // Convert to JSON with headers
    const data = XLSX.utils.sheet_to_json(firstSheet, {
      header: 1,
      blankrows: false,
      raw: false
    });

    if (!data || data.length < 2) { // Need at least headers and one row
      throw new Error('No data found in Excel file');
    }

    // Convert array format to object format with headers
    const headers = data[0] as string[];
    const rows = data.slice(1).map(row => {
      const obj: any = {};
      headers.forEach((header, index) => {
        obj[header] = row[index];
      });
      return obj;
    });

    // Validate data structure
    if (!validateDataStructure(rows)) {
      throw new Error('Invalid data structure in Excel file');
    }

    return rows;
  } catch (error) {
    console.error('Excel processing error:', error);
    throw new Error('Failed to process Excel file: ' + (error instanceof Error ? error.message : 'Unknown error'));
  }
}

function validateDataStructure(data: any[]): boolean {
  if (!Array.isArray(data) || data.length === 0) {
    return false;
  }

  // Check first row for structure
  const firstRow = data[0];
  if (!firstRow || typeof firstRow !== 'object') {
    return false;
  }

  // Ensure all rows have the same columns
  const columns = Object.keys(firstRow);
  if (columns.length === 0) {
    return false;
  }

  // Validate all rows have the same structure
  return data.every(row => {
    if (!row || typeof row !== 'object') return false;
    const rowColumns = Object.keys(row);
    return columns.every(col => rowColumns.includes(col));
  });
}