import React from 'react';
import { motion } from 'framer-motion';
import { 
  MessageSquare, Shield, Smartphone, PieChart, 
  Share2, Database, Bot, LineChart 
} from 'lucide-react';

const features = [
  {
    icon: MessageSquare,
    title: "Natural Language Querying",
    description: "Ask questions about your data in plain English and get instant visualizations"
  },
  {
    icon: Shield,
    title: "Enterprise-Grade Security",
    description: "GDPR and HIPAA compliant with end-to-end encryption"
  },
  {
    icon: Smartphone,
    title: "Mobile-First Design",
    description: "Access your insights anywhere with our responsive web and mobile apps"
  },
  {
    icon: PieChart,
    title: "Custom Dashboards",
    description: "Build and customize dashboards with drag-and-drop simplicity"
  },
  {
    icon: Share2,
    title: "Team Collaboration",
    description: "Share insights and work together in real-time with your team"
  },
  {
    icon: Database,
    title: "Data Integration",
    description: "Connect with popular platforms like Salesforce, Google Analytics, and more"
  },
  {
    icon: Bot,
    title: "AI Assistant",
    description: "Get personalized recommendations and insights from our AI engine"
  },
  {
    icon: LineChart,
    title: "Advanced Analytics",
    description: "Powerful statistical analysis and predictive modeling capabilities"
  }
];

export default function Features() {
  return (
    <section id="features" className="py-20 bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 sm:text-4xl mb-4">
            Everything you need to master your data
          </h2>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Powerful features designed to help you transform raw data into meaningful insights
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              viewport={{ once: true }}
              className="bg-white p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow"
            >
              <feature.icon className="h-8 w-8 text-indigo-600 mb-4" />
              <h3 className="text-lg font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}