import html2canvas from 'html2canvas';

export async function exportChartAsImage(chartId: string, filename: string): Promise<void> {
  try {
    const chartElement = document.getElementById(chartId);
    if (!chartElement) {
      throw new Error('Chart element not found');
    }

    // Store original background
    const originalBg = chartElement.style.background;
    
    // Set white background for export
    chartElement.style.background = 'white';

    const canvas = await html2canvas(chartElement, {
      backgroundColor: 'white',
      scale: 2, // Higher resolution
      logging: false,
      useCORS: true,
      allowTaint: true
    });

    // Restore original background
    chartElement.style.background = originalBg;

    // Create download link
    const link = document.createElement('a');
    link.download = `${filename.toLowerCase().replace(/\s+/g, '-')}.png`;
    link.href = canvas.toDataURL('image/png');
    link.click();
  } catch (error) {
    console.error('Error exporting chart:', error);
    throw new Error('Failed to export chart');
  }
}