import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { AlertCircle, Loader2, Info } from 'lucide-react';
import { useStore } from '../../store/useStore';
import DashboardLayout from '../../components/dashboard/Layout';
import Chart from '../../components/charts/Chart';
import { generateCharts } from '../../lib/chartAnalysis';
import { exportChartAsImage } from '../../lib/utils/chartExport';
import type { ChartData } from '../../types/charts';

export default function ViewDashboardPage() {
  const { id } = useParams();
  const location = useLocation();
  const { connectedDataSources } = useStore();
  const [charts, setCharts] = useState<ChartData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  const dataSource = connectedDataSources.find(ds => ds.id === id);

  useEffect(() => {
    const generateVisualizations = async () => {
      if (!dataSource?.data) {
        setError('No data available for analysis');
        setLoading(false);
        return;
      }

      try {
        // Use charts from location state if available, otherwise generate new ones
        const visualizations = location.state?.charts || generateCharts(dataSource.data);
        setCharts(visualizations);
        setError('');
      } catch (err) {
        console.error('Error analyzing data:', err);
        setError(err instanceof Error ? err.message : 'Failed to analyze data');
      } finally {
        setLoading(false);
      }
    };

    generateVisualizations();
  }, [dataSource, location.state]);

  const handleDownload = async (chartIndex: number) => {
    try {
      await exportChartAsImage(
        `chart-${chartIndex}`,
        charts[chartIndex].title
      );
    } catch (error) {
      console.error('Error downloading chart:', error);
      setError('Failed to download chart. Please try again.');
    }
  };

  return (
    <DashboardLayout>
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
          {dataSource?.name || 'Data Analysis'}
        </h2>
        <p className="mt-1 text-gray-600 dark:text-gray-400">
          Visual analysis of your data
        </p>

        {/* AI Analysis Disclaimer */}
        <div className="mt-4 p-4 bg-blue-50 dark:bg-blue-900/20 rounded-lg flex items-start">
          <Info className="h-5 w-5 text-blue-600 dark:text-blue-400 mt-0.5 mr-2 flex-shrink-0" />
          <div className="text-sm text-blue-700 dark:text-blue-200">
            <p className="font-medium mb-1">AI-Generated Visualizations</p>
            <p>
              These charts are automatically generated using AI analysis of your data. 
              While we strive for accuracy, please review the visualizations and verify 
              any critical insights against your raw data.
            </p>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="flex items-center justify-center h-64">
          <Loader2 className="h-8 w-8 text-indigo-600 dark:text-indigo-400 animate-spin" />
        </div>
      ) : error ? (
        <div className="bg-red-50 dark:bg-red-900/50 p-4 rounded-lg text-red-700 dark:text-red-200 flex items-center">
          <AlertCircle className="h-5 w-5 mr-2" />
          <p>{error}</p>
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          {charts.map((chart, index) => (
            <Chart
              key={index}
              chart={chart}
              index={index}
              onDownload={handleDownload}
            />
          ))}
        </div>
      )}
    </DashboardLayout>
  );
}