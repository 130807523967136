import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DashboardLayout from '../../components/dashboard/Layout';
import { useStore } from '../../store/useStore';
import { Database, TrendingUp, Table, BarChart as ChartIcon, HelpCircle, Crown } from 'lucide-react';
import { useHandlePaymentSuccess } from '../../lib/stripe/hooks';

export default function DashboardPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { userData, connectedDataSources, updateUserPlan } = useStore();
  
  // Handle payment success
  useHandlePaymentSuccess();

  // Calculate data points from all connected sources
  const totalDataPoints = connectedDataSources.reduce((total, source) => {
    return total + (source.data?.length || 0);
  }, 0);

  // Calculate active sources percentage
  const activeSources = connectedDataSources.filter(s => s.status === 'connected').length;
  const totalSources = connectedDataSources.length;

  // Get current time of day for personalized greeting
  const hour = new Date().getHours();
  let greeting = 'Good evening';
  if (hour < 12) greeting = 'Good morning';
  else if (hour < 18) greeting = 'Good afternoon';

  const firstName = userData?.firstName || 'there';

  const handleUpgradeClick = () => {
    navigate('/checkout', { 
      state: { 
        plan: 'pro',
        returnUrl: '/dashboard'
      }
    });
  };

  return (
    <DashboardLayout>
      <div className="mb-8">
        <div className="flex items-center justify-between">
          <div>
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white">
              {greeting}, {firstName}! 👋
            </h2>
            <p className="mt-1 text-gray-600 dark:text-gray-400">
              {totalSources === 0 
                ? "Let's get started by connecting your first data source"
                : "Here's what's happening with your data today"}
            </p>
          </div>
          <div className={`flex items-center px-4 py-2 rounded-full ${
            userData?.planId === 'pro'
              ? 'bg-gradient-to-r from-indigo-500 to-purple-600 text-white'
              : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
          }`}>
            {userData?.planId === 'pro' ? (
              <>
                <Crown className="h-5 w-5 mr-2" />
                <span className="font-medium">Pro Plan</span>
              </>
            ) : (
              <>
                <span className="font-medium">Basic Plan</span>
                <button
                  onClick={handleUpgradeClick}
                  className="ml-3 text-sm text-indigo-600 dark:text-indigo-400 hover:text-indigo-500 dark:hover:text-indigo-300"
                >
                  Upgrade to Pro →
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Quick stats */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
          <div className="flex items-center justify-between mb-4">
            <Database className="h-8 w-8 text-indigo-600 dark:text-indigo-400" />
            <div className="group relative">
              <HelpCircle className="h-5 w-5 text-gray-400 cursor-help" />
              <div className="hidden group-hover:block absolute right-0 w-64 p-2 mt-2 text-sm text-gray-600 bg-white dark:bg-gray-700 dark:text-gray-300 rounded-lg shadow-lg border border-gray-200 dark:border-gray-600 z-10">
                Data sources are your connected files, databases, or services that provide data for analysis.
              </div>
            </div>
          </div>
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Connected Sources</h3>
          <p className="mt-2 text-3xl font-semibold text-gray-900 dark:text-white">{activeSources}</p>
          <div className="mt-2">
            <p className="text-sm text-gray-600 dark:text-gray-400">
              {activeSources === 0 ? (
                "You haven't connected any data sources yet. Add one to get started!"
              ) : (
                `${activeSources} active source${activeSources !== 1 ? 's' : ''} out of ${totalSources} total`
              )}
            </p>
            {userData?.planId === 'basic' && (
              <p className="mt-1 text-xs text-indigo-600 dark:text-indigo-400">
                Basic plan limited to 1 data source. Upgrade to Pro for unlimited sources.
              </p>
            )}
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
          <div className="flex items-center justify-between mb-4">
            <Table className="h-8 w-8 text-indigo-600 dark:text-indigo-400" />
            <div className="group relative">
              <HelpCircle className="h-5 w-5 text-gray-400 cursor-help" />
              <div className="hidden group-hover:block absolute right-0 w-64 p-2 mt-2 text-sm text-gray-600 bg-white dark:bg-gray-700 dark:text-gray-300 rounded-lg shadow-lg border border-gray-200 dark:border-gray-600 z-10">
                Each record represents one row of data in your connected sources.
              </div>
            </div>
          </div>
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Total Records</h3>
          <p className="mt-2 text-3xl font-semibold text-gray-900 dark:text-white">
            {totalDataPoints.toLocaleString()}
          </p>
          <div className="mt-2">
            <p className="text-sm text-gray-600 dark:text-gray-400">
              {totalDataPoints === 0 ? (
                "No data records yet. Connect a data source to see your data here."
              ) : (
                `Total rows of data across all your connected sources`
              )}
            </p>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
          <div className="flex items-center justify-between mb-4">
            <TrendingUp className="h-8 w-8 text-indigo-600 dark:text-indigo-400" />
            <div className="group relative">
              <HelpCircle className="h-5 w-5 text-gray-400 cursor-help" />
              <div className="hidden group-hover:block absolute right-0 w-64 p-2 mt-2 text-sm text-gray-600 bg-white dark:bg-gray-700 dark:text-gray-300 rounded-lg shadow-lg border border-gray-200 dark:border-gray-600 z-10">
                Shows when your data was last updated. Recent updates ensure you're working with the latest information.
              </div>
            </div>
          </div>
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">Last Update</h3>
          <p className="mt-2 text-lg font-semibold text-gray-900 dark:text-white">
            {connectedDataSources[0]?.lastSync 
              ? new Date(connectedDataSources[0].lastSync).toLocaleString()
              : 'No updates yet'}
          </p>
          <div className="mt-2">
            <p className="text-sm text-gray-600 dark:text-gray-400">
              {connectedDataSources[0]?.lastSync ? (
                "Time of the most recent data refresh"
              ) : (
                "Connect a data source to start tracking updates"
              )}
            </p>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
          <div className="flex items-center justify-between mb-4">
            <ChartIcon className="h-8 w-8 text-indigo-600 dark:text-indigo-400" />
            <div className="group relative">
              <HelpCircle className="h-5 w-5 text-gray-400 cursor-help" />
              <div className="hidden group-hover:block absolute right-0 w-64 p-2 mt-2 text-sm text-gray-600 bg-white dark:bg-gray-700 dark:text-gray-300 rounded-lg shadow-lg border border-gray-200 dark:border-gray-600 z-10">
                Indicates if all your data connections are working properly.
              </div>
            </div>
          </div>
          <h3 className="text-sm font-medium text-gray-500 dark:text-gray-400">System Health</h3>
          <p className="mt-2 text-lg font-semibold text-green-600 dark:text-green-400">Healthy</p>
          <div className="mt-2">
            <p className="text-sm text-gray-600 dark:text-gray-400">
              All systems are operating normally
            </p>
          </div>
        </div>
      </div>

      {/* Connected Sources List */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm">
        <div className="px-6 py-4 border-b border-gray-200 dark:border-gray-700">
          <h3 className="text-lg font-semibold dark:text-white">Your Data Sources</h3>
          <p className="text-sm text-gray-600 dark:text-gray-400 mt-1">
            Here's a list of all your connected data sources and their current status
          </p>
        </div>
        <div className="p-6">
          {connectedDataSources.length > 0 ? (
            <div className="space-y-4">
              {connectedDataSources.map((source) => (
                <div key={source.id} className="flex items-center justify-between p-4 border dark:border-gray-700 rounded-lg">
                  <div className="flex items-center">
                    <Database className="h-5 w-5 text-indigo-600 dark:text-indigo-400 mr-3" />
                    <div>
                      <h4 className="font-medium dark:text-white">{source.name}</h4>
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        Last updated: {new Date(source.lastSync || '').toLocaleString()}
                      </p>
                    </div>
                  </div>
                  <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                    source.status === 'connected'
                      ? 'bg-green-100 text-green-800 dark:bg-green-800 dark:text-green-100'
                      : source.status === 'error'
                      ? 'bg-red-100 text-red-800 dark:bg-red-800 dark:text-red-100'
                      : 'bg-yellow-100 text-yellow-800 dark:bg-yellow-800 dark:text-yellow-100'
                  }`}>
                    {source.status === 'connected' ? '✓ Active' : 
                     source.status === 'error' ? '⚠ Error' : 
                     '⏸ Paused'}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-12">
              <p className="text-gray-500 dark:text-gray-400">Ready to get started?</p>
              <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">
                Connect your first data source to begin analyzing your data. You can connect spreadsheets, databases, or various online services.
              </p>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
}