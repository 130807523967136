import React from 'react';
import { Crown, X } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

interface UpgradeModalProps {
  onClose: () => void;
}

export default function UpgradeModal({ onClose }: UpgradeModalProps) {
  const navigate = useNavigate();

  const handleUpgrade = () => {
    navigate('/checkout', { 
      state: { 
        plan: 'pro',
        returnUrl: '/dashboard/data-sources'
      }
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg max-w-md w-full p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <Crown className="h-6 w-6 text-yellow-500 mr-2" />
            <h3 className="text-xl font-semibold dark:text-white">Upgrade to Pro</h3>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <div className="mb-6">
          <p className="text-gray-600 dark:text-gray-300 mb-4">
            Basic plan users can only connect one data source. Upgrade to Pro for:
          </p>
          <ul className="space-y-2">
            <li className="flex items-center text-gray-600 dark:text-gray-300">
              <span className="text-green-500 mr-2">✓</span>
              Unlimited data sources
            </li>
            <li className="flex items-center text-gray-600 dark:text-gray-300">
              <span className="text-green-500 mr-2">✓</span>
              Advanced AI analytics
            </li>
            <li className="flex items-center text-gray-600 dark:text-gray-300">
              <span className="text-green-500 mr-2">✓</span>
              Team collaboration features
            </li>
          </ul>
        </div>

        <div className="bg-indigo-50 dark:bg-indigo-900/20 p-4 rounded-lg mb-6">
          <p className="text-indigo-700 dark:text-indigo-300 font-medium">
            One-time payment of $7.99 for lifetime access!
          </p>
        </div>

        <div className="flex justify-end space-x-3">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-md text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
          >
            Maybe Later
          </button>
          <button
            onClick={handleUpgrade}
            className="px-4 py-2 bg-indigo-600 text-white rounded-md text-sm font-medium hover:bg-indigo-700 dark:bg-indigo-500 dark:hover:bg-indigo-600"
          >
            Upgrade Now
          </button>
        </div>
      </div>
    </div>
  );
}