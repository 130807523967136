import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { supabase } from './lib/supabase';
import Layout from './components/Layout';

// Pages
import HomePage from './pages/HomePage';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import GetStartedPage from './pages/GetStartedPage';
import ContactSalesPage from './pages/ContactSalesPage';
import CheckoutPage from './pages/CheckoutPage';
import PrivacyPage from './pages/PrivacyPage';
import TermsPage from './pages/TermsPage';
import PricingPage from './pages/PricingPage';
import DashboardPage from './pages/dashboard/DashboardPage';
import DataSourcesPage from './pages/dashboard/DataSourcesPage';
import AnalyticsPage from './pages/dashboard/AnalyticsPage';
import AiChatPage from './pages/dashboard/AiChatPage';
import StockMarketPage from './pages/dashboard/StockMarketPage';
import TeamPage from './pages/dashboard/TeamPage';
import SettingsPage from './pages/dashboard/SettingsPage';
import ViewDashboardPage from './pages/dashboard/ViewDashboardPage';
import PreviewPage from './pages/PreviewPage';
import ProtectedRoute from './components/auth/ProtectedRoute';

export default function App() {
  return (
    <SessionContextProvider supabaseClient={supabase}>
      <Layout>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/get-started" element={<GetStartedPage />} />
          <Route path="/contact-sales" element={<ContactSalesPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/pricing" element={<PricingPage />} />
          <Route path="/preview" element={<PreviewPage />} />
          
          {/* Protected Routes */}
          <Route path="/dashboard" element={
            <ProtectedRoute>
              <DashboardPage />
            </ProtectedRoute>
          } />
          <Route path="/dashboard/data-sources" element={
            <ProtectedRoute>
              <DataSourcesPage />
            </ProtectedRoute>
          } />
          <Route path="/dashboard/analytics" element={
            <ProtectedRoute>
              <AnalyticsPage />
            </ProtectedRoute>
          } />
          <Route path="/dashboard/ai-chat" element={
            <ProtectedRoute>
              <AiChatPage />
            </ProtectedRoute>
          } />
          <Route path="/dashboard/stock-market" element={
            <ProtectedRoute>
              <StockMarketPage />
            </ProtectedRoute>
          } />
          <Route path="/dashboard/team" element={
            <ProtectedRoute>
              <TeamPage />
            </ProtectedRoute>
          } />
          <Route path="/dashboard/settings" element={
            <ProtectedRoute>
              <SettingsPage />
            </ProtectedRoute>
          } />
          <Route path="/dashboard/view/:id" element={
            <ProtectedRoute>
              <ViewDashboardPage />
            </ProtectedRoute>
          } />
        </Routes>
      </Layout>
    </SessionContextProvider>
  );
}