import React, { useState } from 'react';
import DashboardLayout from '../../components/dashboard/Layout';
import { Search, TrendingUp, Loader2, AlertCircle } from 'lucide-react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { searchStocks, getStockData } from '../../lib/api/alpha-vantage/service';
import { popularStocks } from '../../lib/api/alpha-vantage/types';
import type { StockData, StockSearchResult } from '../../lib/api/alpha-vantage/types';
import AiStockAnalysis from '../../components/stocks/AiStockAnalysis';

export default function StockMarketPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<StockSearchResult[]>([]);
  const [selectedStock, setSelectedStock] = useState<{ symbol: string; name: string } | null>(null);
  const [stockData, setStockData] = useState<StockData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleSearch = async (query: string) => {
    setSearchQuery(query);
    setError('');

    if (query.length < 2) {
      setSearchResults([]);
      return;
    }

    try {
      const results = await searchStocks(query);
      setSearchResults(results);
    } catch (err) {
      console.error('Search error:', err);
      setSearchResults([]);
      setError('Failed to search stocks. Please try again.');
    }
  };

  const handleStockSelect = async (stock: { symbol: string; name: string }) => {
    if (isLoading) return;

    setIsLoading(true);
    setError('');
    setSelectedStock(stock);
    setSearchQuery('');
    setSearchResults([]);

    try {
      const data = await getStockData(stock.symbol);
      setStockData(data);
    } catch (err) {
      console.error('Error fetching stock data:', err);
      setError(err instanceof Error ? err.message : 'Failed to fetch stock data');
      setStockData(null);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-900 dark:text-white">Stock Market Data</h2>
        <p className="mt-1 text-gray-600 dark:text-gray-400">
          Search and analyze real-time stock market data
        </p>
      </div>

      {/* Search Bar */}
      <div className="relative mb-8">
        <div className="relative">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search stocks by symbol or company name..."
            className="w-full px-4 py-3 pl-12 border border-gray-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-transparent dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
          <Search className="absolute left-4 top-3.5 h-5 w-5 text-gray-400" />
        </div>

        {/* Search Results Dropdown */}
        {searchResults.length > 0 && (
          <div className="absolute z-10 w-full mt-2 bg-white dark:bg-gray-800 rounded-lg shadow-lg border border-gray-200 dark:border-gray-700">
            {searchResults.map((result) => (
              <button
                key={result.symbol}
                onClick={() => handleStockSelect({ symbol: result.symbol, name: result.name })}
                className="w-full px-4 py-3 text-left hover:bg-gray-50 dark:hover:bg-gray-700"
              >
                <div className="flex justify-between items-center">
                  <div>
                    <span className="font-medium dark:text-white">{result.symbol}</span>
                    <p className="text-sm text-gray-600 dark:text-gray-400">{result.name}</p>
                  </div>
                  <TrendingUp className="h-5 w-5 text-gray-400" />
                </div>
              </button>
            ))}
          </div>
        )}
      </div>

      {/* Popular Stocks */}
      {!selectedStock && (
        <div className="mb-8">
          <h3 className="text-lg font-semibold mb-4 dark:text-white">Popular Stocks</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {popularStocks.map((stock) => (
              <button
                key={stock.symbol}
                onClick={() => handleStockSelect(stock)}
                disabled={isLoading}
                className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-shadow border border-gray-200 dark:border-gray-700 disabled:opacity-50"
              >
                <div className="flex items-center justify-between">
                  <div className="text-left">
                    <h4 className="font-medium dark:text-white">{stock.symbol}</h4>
                    <p className="text-sm text-gray-600 dark:text-gray-400">{stock.name}</p>
                  </div>
                  <TrendingUp className="h-5 w-5 text-indigo-600 dark:text-indigo-400" />
                </div>
              </button>
            ))}
          </div>
        </div>
      )}

      {/* Stock Details */}
      {selectedStock && (
        <div>
          {isLoading ? (
            <div className="flex items-center justify-center h-64">
              <Loader2 className="h-8 w-8 text-indigo-600 dark:text-indigo-400 animate-spin" />
            </div>
          ) : error ? (
            <div className="p-4 bg-red-50 dark:bg-red-900/50 rounded-lg flex items-center text-red-700 dark:text-red-200">
              <AlertCircle className="h-5 w-5 mr-2" />
              <p>{error}</p>
            </div>
          ) : stockData && (
            <div className="space-y-6">
              {/* Company Header */}
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
                <div className="flex items-center justify-between mb-4">
                  <div>
                    <h3 className="text-2xl font-bold dark:text-white">{stockData.profile.name}</h3>
                    <p className="text-gray-600 dark:text-gray-400">{stockData.profile.industry}</p>
                  </div>
                  <div className="text-right">
                    <p className="text-2xl font-bold dark:text-white">
                      ${stockData.quote.currentPrice.toFixed(2)}
                    </p>
                    <p className={`text-sm ${
                      stockData.quote.change >= 0
                        ? 'text-green-600 dark:text-green-400'
                        : 'text-red-600 dark:text-red-400'
                    }`}>
                      {stockData.quote.change >= 0 ? '+' : ''}{stockData.quote.changePercent.toFixed(2)}%
                    </p>
                  </div>
                </div>

                {/* Price Chart */}
                <div className="h-64">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={stockData.chartData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis 
                        dataKey="date"
                        tick={{ fontSize: 12 }}
                        interval="preserveStartEnd"
                      />
                      <YAxis 
                        domain={['auto', 'auto']}
                        tick={{ fontSize: 12 }}
                      />
                      <Tooltip />
                      <Line
                        type="monotone"
                        dataKey="price"
                        stroke="#4F46E5"
                        strokeWidth={2}
                        dot={false}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>

              {/* Key Statistics */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
                  <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">Today's Range</h4>
                  <div className="flex items-center justify-between">
                    <span className="text-lg font-semibold dark:text-white">
                      ${stockData.quote.lowPrice.toFixed(2)}
                    </span>
                    <span className="text-lg font-semibold dark:text-white">
                      ${stockData.quote.highPrice.toFixed(2)}
                    </span>
                  </div>
                </div>
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
                  <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">Opening Price</h4>
                  <p className="text-lg font-semibold dark:text-white">
                    ${stockData.quote.openPrice.toFixed(2)}
                  </p>
                </div>
                <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
                  <h4 className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">Previous Close</h4>
                  <p className="text-lg font-semibold dark:text-white">
                    ${stockData.quote.previousClose.toFixed(2)}
                  </p>
                </div>
              </div>

              {/* Company Info */}
              <div className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
                <h4 className="font-semibold mb-4 dark:text-white">Company Information</h4>
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Exchange</p>
                    <p className="font-medium dark:text-white">{stockData.profile.exchange}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Sector</p>
                    <p className="font-medium dark:text-white">{stockData.profile.sector}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Industry</p>
                    <p className="font-medium dark:text-white">{stockData.profile.industry}</p>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500 dark:text-gray-400">Market Cap</p>
                    <p className="font-medium dark:text-white">
                      ${(stockData.profile.marketCap / 1e9).toFixed(2)}B
                    </p>
                  </div>
                </div>
                {stockData.profile.description && (
                  <div className="mt-4">
                    <p className="text-sm text-gray-500 dark:text-gray-400">Description</p>
                    <p className="mt-1 text-sm dark:text-white">{stockData.profile.description}</p>
                  </div>
                )}
              </div>

              {/* AI Analysis */}
              <AiStockAnalysis 
                stockData={stockData} 
                symbol={selectedStock.symbol}
              />
            </div>
          )}
        </div>
      )}
    </DashboardLayout>
  );
}