import { ChartData } from '../types/charts';

// Vibrant color palette for charts
export const CHART_COLORS = [
  '#4F46E5', // Indigo
  '#06B6D4', // Cyan
  '#10B981', // Emerald
  '#F59E0B', // Amber
  '#EF4444', // Red
  '#8B5CF6', // Purple
  '#EC4899', // Pink
  '#14B8A6', // Teal
  '#F97316', // Orange
  '#6366F1'  // Violet
];

export function generateCharts(data: any[]): ChartData[] {
  if (!data || !Array.isArray(data) || data.length === 0) {
    throw new Error('No data available for analysis');
  }

  const columns = Object.keys(data[0]);
  const charts: ChartData[] = [];

  // Analyze numeric columns for bar charts
  const numericColumns = columns.filter(column => {
    return data.every(row => !isNaN(Number(row[column])));
  });

  // Create bar charts for numeric columns
  numericColumns.forEach((column, index) => {
    const values = data.map(row => ({
      name: row[columns[0]] || 'Value',
      value: Number(row[column])
    }));

    charts.push({
      type: 'bar',
      title: `${column} Distribution`,
      data: values.slice(0, 10), // Limit to top 10 values
      color: CHART_COLORS[index % CHART_COLORS.length]
    });
  });

  // Create pie charts for categorical columns
  const categoricalColumns = columns.filter(column => !numericColumns.includes(column));
  
  categoricalColumns.forEach((column, index) => {
    const frequencies: Record<string, number> = {};
    data.forEach(row => {
      const value = String(row[column]);
      frequencies[value] = (frequencies[value] || 0) + 1;
    });

    const pieData = Object.entries(frequencies)
      .map(([name, value]) => ({ name, value }))
      .sort((a, b) => b.value - a.value)
      .slice(0, 8); // Limit to top 8 categories

    if (pieData.length > 1) { // Only add pie chart if there are at least 2 categories
      charts.push({
        type: 'pie',
        title: `${column} Distribution`,
        data: pieData,
        color: CHART_COLORS[(numericColumns.length + index) % CHART_COLORS.length]
      });
    }
  });

  return charts;
}

export function analyzeData(data: any[]): ChartData[] {
  return generateCharts(data);
}