import React, { useRef, useState } from 'react';
import { FileText, Loader2, AlertCircle } from 'lucide-react';

interface FileUploaderProps {
  onFileSelect: (file: File) => Promise<void>;
  isLoading?: boolean;
}

export default function FileUploader({ onFileSelect, isLoading = false }: FileUploaderProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState('');

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    try {
      const fileType = file.name.split('.').pop()?.toLowerCase();
      if (!fileType || !['csv', 'xlsx', 'xls'].includes(fileType)) {
        throw new Error('Please upload a CSV or Excel file');
      }

      setError('');
      await onFileSelect(file);
    } catch (err) {
      console.error('Error uploading file:', err);
      setError(err instanceof Error ? err.message : 'Failed to upload file');
    }

    // Reset input value to allow selecting the same file again
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleClick = () => {
    if (!isLoading && fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div className="text-center">
      <input
        ref={fileInputRef}
        type="file"
        accept=".csv,.xlsx,.xls"
        onChange={handleFileSelect}
        className="hidden"
        disabled={isLoading}
      />

      <button
        onClick={handleClick}
        disabled={isLoading}
        className="w-full max-w-md mx-auto p-8 bg-white dark:bg-gray-800 rounded-lg shadow-sm hover:shadow-md transition-all cursor-pointer"
      >
        {isLoading ? (
          <>
            <Loader2 className="h-16 w-16 text-indigo-600 dark:text-indigo-400 mx-auto animate-spin mb-4" />
            <p className="text-lg font-medium text-gray-900 dark:text-white">
              Processing your file...
            </p>
          </>
        ) : (
          <>
            <FileText className="h-16 w-16 text-indigo-600 dark:text-indigo-400 mx-auto mb-4" />
            <p className="text-lg font-medium text-gray-900 dark:text-white">
              Click to upload a file
            </p>
            <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">
              Excel or CSV files
            </p>
          </>
        )}
      </button>

      {error && (
        <div className="mt-4 p-4 bg-red-50 dark:bg-red-900/50 rounded-lg flex items-center text-red-700 dark:text-red-200">
          <AlertCircle className="h-5 w-5 mr-2 flex-shrink-0" />
          <p>{error}</p>
        </div>
      )}
    </div>
  );
}